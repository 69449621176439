import {
  ConnectedPosition,
  Overlay,
  OverlayPositionBuilder,
  OverlayRef,
} from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import {
  ComponentRef,
  Directive,
  ElementRef,
  HostListener,
  inject,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
} from '@angular/core';

import { TooltipComponent } from './tooltip.component';
import { debounceTime, filter, fromEvent, Subject, takeUntil, tap } from 'rxjs';
import { TooltipPosition } from 'shared/ui/basic/tooltip/interface';

/* eslint-disable @typescript-eslint/member-ordering */
@Directive({
  selector: '[customToolTip]',
})
export class ToolTipRendererDirective implements OnInit, OnDestroy {
  /** This will be used to show tooltip or not This can be used to show the tooltip conditionally */
  @Input() showToolTip = true;
  @Input() theme: 'dark' | 'light' = 'dark';

  // If this is specified then specified template will be rendered in the tooltip
  @Input() contentTemplate!: TemplateRef<any>;
  @Input() context: any;

  // for now, it has no effect, we should clean it up
  // @TODO cleanup
  @Input() position: ConnectedPosition | undefined;

  // for now, top and bottom, left and right should be implemented
  @Input() tooltipPosition: TooltipPosition = 'top';

  private overlayRef!: OverlayRef;
  private overlay = inject(Overlay);
  private overlayPositionBuilder = inject(OverlayPositionBuilder);
  private elementRef = inject(ElementRef);
  private destroy$ = new Subject<void>();

  private shouldShowTooltip = true;

  /** Init life cycle event handler */
  ngOnInit(): void {
    if (!this.showToolTip) {
      return;
    }

    // you can take the position as an input to adjust the position
    // , for now, it will show at the bottom always; but you can adjust your code as per your need
    const positionStrategy = this.overlayPositionBuilder
      .flexibleConnectedTo(this.elementRef)
      .withPositions([this.getPositionDependsOnDirection()]);
    this.overlayRef = this.overlay.create({ positionStrategy });
  }

  //

  /**
   * This method will be called whenever the Mouse enters the Host element i.e. where this directive
   * is applied This method will show the tooltip by instantiating the CustomToolTipComponent and
   * attaching to the overlay
   */
  @HostListener('mouseenter')
  show(): void {
    // attach the component if it has not already attached to the overlay
    if (this.overlayRef && !this.overlayRef.hasAttached()) {
      const tooltipRef: ComponentRef<TooltipComponent> = this.overlayRef.attach(
        new ComponentPortal(TooltipComponent)
      );
      tooltipRef.instance.contentTemplate = this.contentTemplate;
      tooltipRef.instance.theme = this.theme;
      /* eslint-disable @typescript-eslint/no-unsafe-assignment */
      tooltipRef.instance.context = this.context;
      tooltipRef.instance.position = this.tooltipPosition;
      const tooltipHostElement = this.overlayRef.overlayElement;
      this.shouldShowTooltip = true;
      this.registerMouseEnterOnOverlay(tooltipHostElement);
      this.registerMouseleave(tooltipHostElement);
      this.registerMouseleave(this.elementRef.nativeElement as HTMLElement);
    }
  }

  /** Destroy lifecycle event handler This method will make sure to close the tooltip */
  ngOnDestroy(): void {
    this.closeToolTip();
    this.destroy$.next();
    this.destroy$.complete();
  }

  private getPositionDependsOnDirection(): ConnectedPosition {
    switch (this.tooltipPosition) {
      case 'bottom':
        return {
          originX: 'center',
          originY: 'bottom',
          overlayX: 'center',
          overlayY: 'top',
          offsetY: 8,
        };
      default: {
        return {
          originX: 'center',
          originY: 'top',
          overlayX: 'center',
          overlayY: 'bottom',
          offsetY: -8,
        };
      }
    }
  }

  private registerMouseleave(htmlElement: HTMLElement): void {
    fromEvent(htmlElement, 'mouseleave')
      .pipe(
        tap(() => (this.shouldShowTooltip = false)),
        debounceTime(100), // give Time for mouseEnter on the Overlay
        filter(() => !this.shouldShowTooltip),
        tap(() => this.closeToolTip()),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  private registerMouseEnterOnOverlay(overlay: HTMLElement): void {
    fromEvent(overlay, 'mouseenter')
      .pipe(
        tap(() => (this.shouldShowTooltip = true)),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  /** This method will close the tooltip by detaching the component from the overlay */
  private closeToolTip(): void {
    if (this.overlayRef) {
      this.overlayRef.detach();
    }
  }
}
