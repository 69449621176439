import { Component, ElementRef, Input, OnChanges, ViewChild } from '@angular/core';

import { UtilHelper } from 'shared/helper/util-helper';
import { EFileResult } from '../../efile-response.interface';
import { FileUploadFunctionsService } from '../../file-upload-functions.service';

@Component({
  selector: 'ista-daytona-file-upload-result-efile',
  templateUrl: './file-upload-result-efile.component.html',
  styleUrls: ['./file-upload-result-efile.component.scss'],
})
export class FileUploadResultEfileComponent
  extends FileUploadFunctionsService
  implements OnChanges
{
  @Input() file!: EFileResult;
  @ViewChild('eFileContainer') eFileContainer!: ElementRef<HTMLElement>;
  public isOpen = true;

  ngOnChanges(): void {
    this.isOpen = this.existsEFileBillingError(this.file);
  }

  public isMoreThanThreeItems(): boolean {
    let count = this.file?.generalValidationHints.length || 0;
    this.file?.detectedBillings.forEach((item) => {
      count++;
      count += item.validationHints.length;
    });

    return count > 3;
  }

  public scrollToElementTop(): void {
    UtilHelper.scrollToElement(this.eFileContainer.nativeElement);
  }
}
