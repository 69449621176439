import { Component, Input } from '@angular/core';
import { UtilHelper } from 'shared/helper/util-helper';
import { AnalyticalCategories } from 'shared/interfaces/consumption-type-button.interface';
import { ValueItem } from 'shared/models/value-item.interface';
import { CardHeaderConfig } from 'shared/models/card-header-config.interface';
import { FilterDateRange } from '../../filter/filter-tab.interface';
import { Unit } from 'shared/models/unit.enum';
import { ChartItem, ChartItemData } from '../chart.interface';
import { BalanceChartConfig } from './balance-chart-config.interface';
import { IconValueLabel } from 'shared/ui/icon-value-label/icon-value-label.interface';
import { DashboardHelper } from 'app/features/dashboard/utils/dashboard-helper';

/* eslint-disable @typescript-eslint/member-ordering */
@Component({
  selector: 'ista-daytona-balance-chart',
  templateUrl: './balance-chart.component.html',
  styleUrls: ['./balance-chart.component.scss'],
})
export class BalanceChartComponent {
  @Input() barChartData!: ChartItem[];
  @Input() analyticalCategories!: AnalyticalCategories | undefined;
  public filteredChartData: ChartItem[] = [];
  public totalSumChartData!: ChartItem;
  public doughnutConfig!: CardHeaderConfig;
  private _filteredResultTypes: string[] = ['heatingAndHotWater', 'electricity', 'water', 'waste'];
  private _balanceChartData!: ChartItem[];

  get doughnutChartData(): ChartItem[] {
    return this._balanceChartData;
  }

  @Input() set doughnutChartData(value: ChartItem[]) {
    this._balanceChartData = value;
    this.filteredChartData = this._balanceChartData?.filter((item) =>
      this._filteredResultTypes.includes(item.label)
    );
    this._transformTotalSumChartItems();
    this._setConfig();
  }

  private _selectedYear!: FilterDateRange;

  get selectedYear(): FilterDateRange {
    return this._selectedYear;
  }

  @Input() set selectedYear(value: FilterDateRange | undefined) {
    if (value) {
      this._selectedYear = value;
      this._transformTotalSumChartItems();
      this._setConfig();
    }
  }

  private _config!: BalanceChartConfig;

  get config(): BalanceChartConfig | undefined {
    return this._config;
  }

  @Input() set config(value: BalanceChartConfig | undefined) {
    if (value) {
      this._config = value;
      this._setConfig();
    }
  }

  // @TODO this can be calculated in Selectors
  public getValueItem(data: ChartItemData[]): { value?: number | null; unit: string } {
    const foundItem = data?.find((item) => item.year === this.selectedYear?.from)?.values;

    return {
      value: foundItem?.value,
      unit: UtilHelper.getUnitEnumValue(foundItem?.unit || Unit.DEFAULT),
    };
  }

  // @TODO Move Later to Dashboard Selectors and Extend The Component to get it as Input
  toIconValueLabel(items: ChartItem[]): IconValueLabel[] {
    return items.map((item) => {
      const val = this.getValueItem(item.data);

      return DashboardHelper.chartItemToIconValueLabel(item, val);
    });
  }

  private _transformTotalSumChartItems(): void {
    const totalSum = this._balanceChartData?.find((item) => item.label === 'totalSum');

    if (totalSum) {
      this.totalSumChartData = {
        ...totalSum,
        data: this.getDisplayedBars(totalSum.data),
      };
    }
  }

  private getDisplayedBars(data: ChartItemData[]): ChartItemData[] {
    const index = data?.findIndex((item) => item.year === this.selectedYear?.from);

    const start = index - 2 >= 0 ? index - 2 : 0;
    let end = index < 0 ? 0 : index + 1;
    if (start === 0) {
      end = 3;
    }

    return data.slice(start, end);
  }

  private _setConfig(): void {
    const foundConfig = this.doughnutChartData?.find((item) => item.label === 'totalSum');
    if (foundConfig) {
      const value: ValueItem | undefined = foundConfig?.data?.find(
        (dataItem) => dataItem.year === this.selectedYear?.from
      )?.values;
      this.doughnutConfig = {
        label: Math.floor(value?.value || 0).toString(),
        unit: value?.unit || Unit.DEFAULT,
      };
    }
  }
}
