import { Component, Input, OnChanges } from '@angular/core';

import { UtilHelper } from 'shared/helper/util-helper';
import { ValueUnitService } from 'shared/helper/value-unit.service';
import { ValueItem } from 'shared/models/value-item.interface';
import { Unit, UnitFactor, UnitWithPrefix } from '../../../models/unit.enum';
import { LabelMapping } from './label-mapping';
import { LabelConfig } from './label.interface';

@Component({
  selector: 'ista-daytona-label',
  templateUrl: './label.component.html',
  styleUrls: ['./label.component.scss'],
})
export class LabelComponent implements OnChanges {
  @Input() config: LabelConfig = {
    consumptionTypes: 'all',
  };
  @Input() overwriteUnit!: Unit | UnitWithPrefix;
  public unitStr = '';
  private labelMapping = LabelMapping;

  ngOnChanges(): void {
    if (this.config) {
      let transformToString = true;
      const chartLabel = this.labelMapping.find(
        (item) =>
          item.analyticalCategories === this.config?.analyticalCategory &&
          item.consumptionInfo.includes(this.config?.consumptionTypes || 'all')
      );

      const value: ValueItem = {
        value: null,
        unit: chartLabel?.unit,
      };

      const transformedValueItem = ValueUnitService.getMappedValueByFactor(value, UnitFactor.K);

      if (chartLabel?.unit && this.config?.unitFactor) {
        const nextUnitPrefix = ValueUnitService.getNextUnitPrefix(
          chartLabel.unit,
          this.config.unitFactor
        );

        transformedValueItem.unitWithPrefix = nextUnitPrefix;
        transformToString = false;
      }

      if (!!transformToString) {
        this.unitStr = UtilHelper.getUnitEnumValue(transformedValueItem.unitWithPrefix || '');
      } else {
        this.unitStr = transformedValueItem.unitWithPrefix;
      }

      if (this.overwriteUnit) {
        this.unitStr = this.overwriteUnit;
      }
    }
  }
}
