import { CustomIcon } from 'shared/interfaces/custom-icon.interface';

export const IconHelper = new Map([
  ['heatingAndHotWater', 'assets/icons/charts/card-waerme.svg'],
  ['heating', 'assets/icons/charts/card-waerme.svg'],
  ['electricity', 'assets/icons/charts/card-lightning.svg'],
  ['water', 'assets/icons/charts/water.svg'],
  ['hotWaterEnergy', 'assets/icons/charts/Drop.svg'],
  ['waste', 'assets/icons/charts/Trash.svg'],
  ['districtCooling', 'assets/icons/charts/Snowflake.svg'],
  ['building', 'assets/icons/cards/Building.svg'],
  ['site-count-building', 'assets/icons/cards/Building-grey.svg'],
  ['room', 'assets/icons/cards/Room.svg'],
  ['utilityUnit', 'assets/icons/cards/Nutzeinheit.svg'],
  ['co2-green', 'assets/icons/charts/co2-green.svg'],
  ['co2', 'assets/icons/charts/card-co2.svg'],
  ['euro', 'assets/icons/charts/CurrencyEur.svg'],
  ['tab-overview', 'assets/icons/navigation/monitoring.svg'],
  ['', ''],
]);

export const TypesIcon: Record<string, string> = {
  heatingAndHotWater: 'local_fire_department',
  heating: '',
  electricity: 'bolt',
  water: 'water_drop',
  hotWaterEnergy: '',
  waste: 'delete',
};

export const MedianCustomIcons: Record<string, CustomIcon> = {
  heatingAndHotWater: {
    name: 'heating',
    isSvgIcon: true,
    isStrokeRelated: true,
    colorClass: 'stroke-heating-800',
  },
  heating: {
    name: 'heating',
    isSvgIcon: true,
    isStrokeRelated: true,
    colorClass: 'stroke-heating-800',
  },
  electricity: {
    name: 'electricity',
    isSvgIcon: true,
    isFillRelated: true,
    colorClass: 'fill-electricity-800',
  },
  co2: {
    name: 'emissions',
    isSvgIcon: true,
    isStrokeRelated: true,
    colorClass: 'stroke-co2-800',
  },
  waste: {
    name: 'waste',
    isSvgIcon: true,
    isFillRelated: true,
    colorClass: 'fill-waste-800',
  },
  water: {
    name: 'water',
    isSvgIcon: true,
    isFillRelated: true,
    colorClass: 'fill-water-800',
  },
  hotWaterEnergy: {
    name: 'water',
    isSvgIcon: true,
    isFillRelated: true,
    colorClass: 'fill-heating-500',
  },
  cost: {
    name: 'circle_cost',
    isSvgIcon: true,
    isStrokeRelated: true,
    colorClass: 'stroke-cost-800',
  },
};

export const MedianBackgroundCustomIconsClass: Record<string, string> = {
  heatingAndHotWater: 'bg-heating-500',
  heating: 'bg-heating-500',
  electricity: 'bg-electricity-500',
  co2: 'bg-co2-500',
  waste: 'bg-waste-500',
  water: 'bg-water-500',
};
