import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes, TitleStrategy } from '@angular/router';
import { TitleStrategyService } from 'core/title-strategy.service';
import { ResetHeadlineGuard } from 'shared/guards/reset-headline.guard';
import { ResetStickyHeaderGuard } from 'shared/guards/reset-sticky-header.guard';
import { ContactComponent } from './static-pages/contact/contact.component';
import { ImprintComponent } from './static-pages/imprint/imprint.component';
import { PageNotFoundComponent } from './static-pages/page-not-found/page-not-found.component';
import { PrivacyComponent } from './static-pages/privacy/privacy.component';
import { emptyEnvironmentGuard } from 'shared/ui/empty-environment-notification/empty-environment.guard';
import { UnsavedChangesGuard } from 'app/features/settings/unsaved-changes.guard';
import { ResetEmissionMediaChartFilterGuard } from 'app/features/benchmarking/guards/reset-emission-media-chart-filter.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: 'stylebook',
    loadChildren: () =>
      import('./features/style-book/style-book.module').then((m) => m.StyleBookModule),
  },
  {
    path: 'benchmarking',
    loadChildren: () =>
      import('./features/benchmarking/benchmarking.module').then((m) => m.BenchmarkingModule),
    data: {
      breadcrumbTitle: 'navigation.benchmarking',
      showGlobalYearSelection: true,
      hideSelectedPeriodInfo: true,
      showEnvironmentContainer: true,
    },
    title: 'navigation.benchmarking',
    canActivate: [emptyEnvironmentGuard],
    canDeactivate: [ResetStickyHeaderGuard, ResetEmissionMediaChartFilterGuard],
  },
  {
    path: 'logout',
    loadChildren: () => import('./features/logout/logout.module').then((m) => m.LogoutModule),
  },
  {
    path: 'settings',
    loadComponent: () =>
      import('./features/settings/settings.component').then((m) => m.SettingsComponent),
    data: { breadcrumbTitle: 'navigation.preferences' },
    title: 'navigation.preferences',
    canDeactivate: [UnsavedChangesGuard],
  },
  {
    path: 'data-gap',
    loadChildren: () => import('./features/data-gap/data-gap.module').then((m) => m.DataGapModule),
    title: 'navigation.data-gap',
    data: {
      breadcrumbTitle: 'navigation.data-gap',
      showEnvironmentContainer: true,
      showGlobalYearSelection: true,
    },
    canDeactivate: [ResetHeadlineGuard, ResetStickyHeaderGuard],
  },
  {
    path: 'portfolio',
    loadChildren: () =>
      import('./features/portfolio/portfolio.module').then((m) => m.PortfolioModule),
    title: 'navigation.portfolio',
    data: {
      breadcrumbTitle: 'navigation.portfolio',
      showSubNavigationMenu: true,
      showEnvironmentContainer: true,
      showGlobalYearSelection: true,
      subNavigationHeadline:
        "<a href='/portfolio' class='text-inherit hover:text-primary'>Portfolio</a>",
    },
    canActivate: [emptyEnvironmentGuard],
    canDeactivate: [ResetHeadlineGuard, ResetStickyHeaderGuard],
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./features/dashboard/dashboard.module').then((m) => m.DashboardModule),
    title: 'navigation.dashboard',
    data: {
      breadcrumbTitle: 'navigation.dashboard',
      showEnvironmentContainer: true,
      showGlobalYearSelection: true,
    },
    canActivate: [emptyEnvironmentGuard],
    canDeactivate: [ResetHeadlineGuard, ResetStickyHeaderGuard],
  },
  {
    path: 'help',
    loadComponent: () => import('./static-pages/help/help.component').then((c) => c.HelpComponent),
    data: { breadcrumbTitle: 'navigation.help' },
    title: 'navigation.help',
  },
  {
    path: 'imprint',
    component: ImprintComponent,
    title: 'Imprint',
    data: { breadcrumbTitle: 'Impressum', showFooterWave: true },
  },
  {
    path: 'privacy',
    component: PrivacyComponent,
    data: { breadcrumbTitle: 'Datenschutz', showFooterWave: true },
    title: 'Datenschutz',
  },
  {
    path: 'contact',
    component: ContactComponent,
    data: { breadcrumbTitle: 'navigation.contact', showFooterWave: false },
    title: 'navigation.contact',
  },
  {
    path: 'reporting',
    loadChildren: () =>
      import('./features/reporting/reporting.module').then((c) => c.ReportingModule),
    data: { breadcrumbTitle: 'navigation.reporting' },
    title: 'navigation.reporting',
    canActivate: [emptyEnvironmentGuard],
  },
  {
    path: 'data-upload',
    loadChildren: () =>
      import('./features/daten-upload/daten-upload.module').then((c) => c.DatenUploadModule),
    data: { breadcrumbTitle: 'navigation.dataUpload', showFooterWave: false },
    title: 'navigation.dataUpload',
  },
  {
    path: 'page-not-found-404',
    component: PageNotFoundComponent,
    data: { title: 'Page not found - 404' },
  },
  {
    path: '**',
    redirectTo: 'page-not-found-404',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
    }),
  ],
  exports: [RouterModule],
  providers: [
    {
      provide: TitleStrategy,
      useClass: TitleStrategyService,
    },
  ],
})
export class AppRoutingModule {}
