import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { FilterTabItem } from '../../filter/filter-tab.interface';

@Component({
  selector: 'ista-daytona-selection',
  templateUrl: './selection.component.html',
})
export class SelectionComponent implements AfterViewInit {
  @Input() selections: FilterTabItem[] = [];
  @Output() selectTab = new EventEmitter<FilterTabItem>();
  public selectedTab!: FilterTabItem;

  ngAfterViewInit(): void {
    this.selectedTab = this.selections[0];
  }

  public setSelectedTab(tab: FilterTabItem): void {
    this.selectedTab = tab;
    this.selectTab.emit(this.selectedTab);
  }
}
