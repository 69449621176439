import { HeatingChilds, HeatingTempExcludeChilds } from '../models/heating.type';
import { ChartItem, ChartItemData } from '../ui/chart/chart.interface';
import { FilterDateRange } from '../ui/filter/filter-tab.interface';
import { ColorHelper } from './color-helper';
import { LanguageKeyHelper } from './language-key-helper';
import { ALL_LABEL } from 'shared/constants';

export class ChartHelper {
  static getHeatColor(key: string, index = 0): string | undefined {
    return [...HeatingChilds, ...HeatingTempExcludeChilds].includes(key)
      ? ColorHelper.get(`spaceHeatingEnergy-${index}`)
      : this.getColor(key);
  }

  static getSelectAllFilter(): ChartItem {
    return {
      label: LanguageKeyHelper.get(ALL_LABEL as string) || ALL_LABEL,
      value: ALL_LABEL,
      color: '',
      active: true,
      data: [],
      childs: [],
    };
  }

  static areAllDataValuesUndefined(data: ChartItemData[]): boolean {
    return !data.length || data.every((item) => item?.values?.value === undefined);
  }

  static getColor(key: string): string {
    return ColorHelper.get(key) || ColorHelper.get(key + '-full') || '';
  }

  static getValuesByFilterDateRange(
    data: ChartItemData[],
    selectedYear: FilterDateRange
  ): (number | null)[] {
    return data
      .filter((item) => item.year >= selectedYear.from && item.year <= selectedYear.until)
      ?.map((itemData) => itemData.values?.value);
  }

  static getValueByFilterDates(data: ChartItemData[], selectedYear: string[]): (number | null)[] {
    return data
      .filter((item) => selectedYear.includes(item.year))
      ?.map((itemData) => itemData.values?.value);
  }
}
