import { Component } from '@angular/core';
import { UtilHelper } from 'shared/helper/util-helper';

@Component({
  selector: 'ista-daytona-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss'],
})
export class PrivacyComponent {
  constructor() {
    UtilHelper.scrollToTop();
  }
}
