import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Output,
  SkipSelf,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';
import { MatError, MatFormField } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { MatInput } from '@angular/material/input';
import { debounceTime, distinctUntilChanged, Observer, Subject, takeUntil } from 'rxjs';
import { NAME_EXISTS_ERROR_KEY, Tag, TagForm } from 'core/tags/interface';
import { TagInputErrorMatcher } from 'core/tags/tag-edit-options/tag-input-error-matcher';
import { CustomValidators } from 'core/tags/utils/custom-validators';
import { TagsStore } from 'core/tags/tags.store';

@Component({
  selector: 'ista-daytona-tag-edit-options',
  templateUrl: 'tag-edit-options.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatFormField, ReactiveFormsModule, MatIcon, TranslateModule, MatInput, MatError],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      // eslint-disable-next-line no-use-before-define
      useExisting: forwardRef(() => TagEditOptionsComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      // eslint-disable-next-line no-use-before-define
      useExisting: forwardRef(() => TagEditOptionsComponent),
      multi: true,
    },
  ],
})
export class TagEditOptionsComponent implements OnInit, ControlValueAccessor, OnDestroy, Validator {
  @Output() editTag = new EventEmitter<string>();
  @Output() deleteTag = new EventEmitter<{
    top: number;
    left: number;
  }>();
  @ViewChild('tagEditWrapper') editWrapper!: ElementRef<HTMLDivElement>;
  form: FormGroup = this.fb.group(
    {
      // eslint-disable-next-line @typescript-eslint/unbound-method
      name: ['', [Validators.required, Validators.maxLength(255)]],
      id: '',
      checked: false,
      lastModified: '',
    },
    {
      asyncValidators: [CustomValidators.tagWithSameNameAlreadyExists(this.tagsStore)],
    }
  );
  tagInputErrorMatcher = new TagInputErrorMatcher();
  private onChange = new Subject<Tag>();
  private onTouch = new Subject<Tag>();
  private destroy$ = new Subject<void>();

  constructor(
    @SkipSelf() private tagsStore: TagsStore,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef
  ) {}

  get isNameAlreadyExistError(): boolean {
    return this.form.hasError(NAME_EXISTS_ERROR_KEY);
  }

  get isRequiredError(): boolean {
    return !!this.form.get('name')?.hasError('required');
  }

  get isMaxLengthError(): boolean {
    return !!this.form.get('name')?.hasError('maxlength');
  }

  @Input() set disabled(isDisabled: boolean) {
    if (isDisabled) {
      this.form.disable({ emitEvent: false });
    } else {
      this.form.enable({ emitEvent: false });
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  validate(control: AbstractControl<any, any>): ValidationErrors | null {
    return {
      ...this.form.get('name')?.errors,
      ...this.form.errors,
    };
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  writeValue(tag: TagForm): void {
    if (this.form && tag) {
      this.form.reset(null, {
        emitEvent: false,
      });
      this.form.setValue(tag, {
        emitEvent: false,
        onlySelf: true,
      });
    }
  }

  registerOnChange(fn: Partial<Observer<Tag>> | undefined): void {
    this.onChange.subscribe(fn);
  }

  registerOnTouched(fn: Partial<Observer<Tag>> | undefined): void {
    this.onTouch.subscribe(fn);
  }

  ngOnInit(): void {
    this.form
      .get('name')
      ?.valueChanges.pipe(debounceTime(200), takeUntil(this.destroy$))
      .subscribe(() => {
        this.onChange.next(this.form.value as TagForm);
        this.onTouch.next(this.form.value as TagForm);
      });

    this.form.statusChanges.pipe(distinctUntilChanged(), takeUntil(this.destroy$)).subscribe(() => {
      this.cdr.detectChanges();
    });
  }

  onDeleteClick(): void {
    const { top, left, right } = this.editWrapper.nativeElement.getBoundingClientRect();
    this.deleteTag.emit({
      top,
      left: left + (right - left) / 2,
    });
  }
}
