import { createFeatureSelector, createSelector } from '@ngrx/store';

import { routerStateConfig } from './ngrx-store-router.module';
import { ReducedRouterReducerState } from './reduced-route-state';
import { ENVIRONMENT_ID_QUERY_PARAM, YEAR_QUERY_PARAM } from 'core/constants';

export const selectReducedRouterReducerState = createFeatureSelector<ReducedRouterReducerState>(
  routerStateConfig.stateKey
);
export const selectReducedRouteState = createSelector(
  selectReducedRouterReducerState,
  (routerReducerState) => routerReducerState?.state
);

export const selectRouteData = createSelector(
  selectReducedRouteState,
  (routerReducerState) => routerReducerState?.data
);
export const selectRouteBreadcrumbTitle = createSelector(
  selectRouteData,
  (data) => (data['breadcrumbTitle'] ?? '') as string
);
export const selectRouteUrl = createSelector(
  selectReducedRouteState,
  (routerReducerState) => routerReducerState?.url
);

export const selectRouteUrlWithoutQueryParams = createSelector(
  selectRouteUrl,
  (url) => url?.split('?')[0] ?? ''
);

export const selectRouteQueryParams = createSelector(
  selectReducedRouteState,
  (routerReducerState) => routerReducerState?.queryParams ?? {}
);

export const selectRouteParams = createSelector(
  selectReducedRouteState,
  (routerReducerState) => routerReducerState?.params
);

export const selectShowFooterWave = createSelector(selectRouteData, (data) =>
  data && data.hasOwnProperty('showFooterWave') ? Boolean(data['showFooterWave']) : false
);

export const selectShowSubNavigationMenu = createSelector(selectRouteData, (data) =>
  data && data.hasOwnProperty('showSubNavigationMenu')
    ? Boolean(data['showSubNavigationMenu'])
    : false
);

export const selectShowEnvironmentContainer = createSelector(selectRouteData, (data) =>
  data && data.hasOwnProperty('showEnvironmentContainer')
    ? Boolean(data['showEnvironmentContainer'])
    : false
);

export const selectShowGlobalYearSelection = createSelector(selectRouteData, (data) =>
  data && data.hasOwnProperty('showGlobalYearSelection')
    ? Boolean(data['showGlobalYearSelection'])
    : false
);

export const selectSubNavigationHeadline = createSelector(selectRouteData, (data) =>
  data && data.hasOwnProperty('subNavigationHeadline')
    ? (data['subNavigationHeadline'] as string)
    : ''
);

export const selectQueryParams = createSelector(selectRouteQueryParams, (params) => params);

export const selectGroupIdParam = createSelector(selectRouteParams, (params) =>
  params?.hasOwnProperty('id') ? parseInt(params['id'] as string, 10) : undefined
);

export const selectIsIdParamIdGroup = createSelector(selectRouteUrl, (url) =>
  url?.includes('/portfolio/group/')
);

export const selectIsIdParamIdSite = createSelector(selectRouteUrl, (url) =>
  url?.includes('/portfolio/site/')
);

export const selectIsPortfolioUrl = createSelector(selectRouteUrl, (url) => url === '/portfolio');

export const selectEnvironmentIdQueryParam = createSelector(selectQueryParams, (params) =>
  params.hasOwnProperty(ENVIRONMENT_ID_QUERY_PARAM)
    ? parseInt(params[ENVIRONMENT_ID_QUERY_PARAM] as string, 10)
    : undefined
);

export const selectYearQueryParam = createSelector(selectQueryParams, (params) =>
  params.hasOwnProperty(YEAR_QUERY_PARAM)
    ? parseInt(params[YEAR_QUERY_PARAM] as string, 10)
    : undefined
);

export const selectHideSelectedPeriodInfo = createSelector(selectRouteData, (data) =>
  data && data.hasOwnProperty('hideSelectedPeriodInfo')
    ? Boolean(data['hideSelectedPeriodInfo'])
    : false
);
