import { Component } from '@angular/core';

import { UtilHelper } from 'shared/helper/util-helper';

@Component({
  selector: 'ista-daytona-imprint',
  templateUrl: './imprint.component.html',
  styleUrls: ['./imprint.component.scss'],
})
export class ImprintComponent {
  constructor() {
    UtilHelper.scrollToTop();
  }
}
