import { Component, Input } from '@angular/core';
import { TrackingWrapperService } from 'core/tracking/tracking-wrapper.service';
import { NavigationItem } from './navigation-item.interface';
import { EmptyEnvironmentNotificationService } from 'shared/ui/empty-environment-notification/empty-environment-notification.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'ista-daytona-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent {
  @Input() displaySmall: boolean | null = false;
  public navigationItems: NavigationItem[] = [
    {
      id: 1,
      name: 'navigation.dashboard',
      title: 'navigation.dashboard',
      icon: {
        name: 'dashboard',
        isSvgIcon: true,
        isFillRelated: true,
      },
      link: 'dashboard',
    },
    {
      id: 2,
      name: 'navigation.portfolio',
      title: 'navigation.portfolio',
      icon: {
        name: 'buildings',
        isSvgIcon: true,
        isFillRelated: true,
      },
      link: 'portfolio',
    },
    {
      id: 3,
      name: 'navigation.benchmarking',
      title: 'navigation.benchmarking',
      icon: {
        name: 'finance_chart',
        isSvgIcon: true,
        isFillRelated: true,
      },
      link: 'benchmarking',
    },
    {
      id: 4,
      name: 'navigation.reporting',
      title: 'navigation.reporting',
      icon: {
        name: 'report',
        isSvgIcon: true,
        isFillRelated: true,
      },
      link: 'reporting',
    },
    {
      id: 5,
      name: '',
      link: '',
    },
    {
      id: 6,
      name: 'navigation.contact',
      title: 'navigation.contact',
      icon: {
        name: 'envelop',
        isSvgIcon: true,
        isFillRelated: true,
      },
      link: 'contact',
    },
    {
      id: 7,
      name: 'navigation.help',
      title: 'navigation.help',
      icon: {
        name: 'help',
        isSvgIcon: true,
        isFillRelated: true,
      },
      link: 'help',
    },
    {
      id: 8,
      name: 'navigation.preferences',
      title: 'navigation.preferences',
      icon: {
        name: 'settings',
        isSvgIcon: true,
        isFillRelated: true,
      },
      link: 'settings',
    },
    {
      id: 9,
      name: '',
      link: '',
    },
    {
      id: 10,
      name: 'navigation.dataUpload',
      title: 'navigation.dataUpload',
      icon: {
        name: 'add_circle',
        isSvgIcon: true,
        isFillRelated: true,
      },
      link: 'data-upload',
    },
    {
      id: 11,
      name: 'navigation.logout',
      title: 'navigation.logout',
      icon: {
        name: 'logout',
        isSvgIcon: true,
        isFillRelated: true,
      },
      link: 'logout',
    },
  ];
  constructor(
    private readonly trackingService: TrackingWrapperService,
    private emptyEnvironmentService: EmptyEnvironmentNotificationService,
  ) {}

  getIsLinkActive(link: string): boolean {
    const pathName = location.pathname;
    return pathName === `/${link}` || pathName.startsWith(`/${link}/`);
  }

  public setTitle(name: string): void {
    this.trackingService.addGTM(
      {
        eventCategory: 'elementClick',
        eventAction: 'menuNavigationClick',
        eventLabel: name,
      },
      []
    );
  }

  isDisabledFromMissingData(itemLink: string): Observable<boolean> {
    return this.emptyEnvironmentService.isDisabledFromMissingData(itemLink);
  }
}
