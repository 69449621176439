<div class="doughnut-chart-percent-container grid grid--gap-8">
  <div class="pie-container">
    <div class="pie" [ngStyle]="innerStyle"></div>
    <div class="pie" [ngStyle]="style">
      <span class="value" [class.color--white]="colorStyle === colorStyleEnum.BLUE">{{ value }}</span>
      <span class="unit" [class.color--white]="colorStyle === colorStyleEnum.BLUE">%</span>
    </div>
  </div>

  <div class="info-container grid grid--col-2">
    @if(imageSrc; as icon) {
      @if(icon.isSvgIcon){
        <mat-icon [svgIcon]="icon.name" [class]="icon.colorClass"></mat-icon>
      }
      @else {
        <mat-icon [class]="icon.colorClass">{{icon.name}}</mat-icon>
      }
    }
    <span [class.color--white]="colorStyle === colorStyleEnum.BLUE">{{ label | translate }}</span>
  </div>
</div>
