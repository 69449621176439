import { createSelector } from '@ngrx/store';
import { RootState } from 'app/+state/app.reducer';
import {
  selectShowEnvironmentContainer,
  selectShowGlobalYearSelection,
} from 'app/+state/router/reduced-route.selectors';
import { GroupMapper } from 'core/groups/group.mapper';
import { FilterDateRange, FilterTabItem } from 'shared/ui/filter/filter-tab.interface';

import { GroupHelper } from 'core/groups-tree-navigation/utils/group-helper';
import { CoreState } from 'core/+state/interface';

export const selectCore = (state: RootState): CoreState => state.core;
export const selectFrontendConfig = createSelector(selectCore, (state) => state.frontendConfig);
export const selectUserToken = createSelector(selectCore, (state) => state.userToken);
export const selectIsUserLoggedIn = createSelector(selectCore, (state) => !!state.userToken);
export const selectAvailableEnvironment = createSelector(
  selectCore,
  (state) => state.availableEnvironments
);
export const selectSelectedEnvironment = createSelector(
  selectCore,
  (state) => state?.selectedEnvironment
);
export const selectSelectedEnvironmentId = createSelector(
  selectSelectedEnvironment,
  (env) => env?.id
);
export const selectYearsWithData = createSelector(selectCore, (state) => state.yearsWithData);

export const selectEnvironmentHasNoData = createSelector(selectCore, (state) => state.yearsWithData?.length === 0);

export const selectYearsWithDataSortedDesc = createSelector(selectCore, (state) =>
  [...state.yearsWithData ].sort((a, b) => b - a)
);

export const selectYearsWithDataSortedDescAsString = createSelector(
  selectYearsWithDataSortedDesc,
  (state) => state.map((value) => value.toString())
);

export const selectGlobalSelectedYear = createSelector(
  selectCore,
  (state) => state?.globalSelectedYear
);

export const selectGlobalSelectedYearAsFilterRange = createSelector(
  selectCore,
  (state) =>
    ({
      from: state.globalSelectedYear ? `${state.globalSelectedYear}` : '',
      until: '',
    } as FilterDateRange)
);

export const selectYearsWithDataAsFilterTabItems = createSelector(
  selectYearsWithData,
  selectGlobalSelectedYear,
  (years: number[], globalSelectedYear: number | undefined): FilterTabItem[] =>
    years.map((item) => ({
      label: item.toString(),
      value: item.toString(),
      active: item === globalSelectedYear,
    }))
);

export const selectYearsCount = createSelector(selectCore, (state) => state.yearsWithData?.length);

export const selectEnvironmentReadiness = createSelector(
  selectCore,
  (state) => state.environmentReady
);

export const selectGroups = createSelector(selectCore, (state) => state.groups);

export const selectTreeNavigation = createSelector(selectCore, (state) => state.treeNavigation);
export const selectSearchTreeNavigation = createSelector(
  selectCore,
  (state) => state.searchTreeNavigation
);
export const selectTreeNavigationGroups = createSelector(
  selectTreeNavigation,
  (tree) => tree.groups
);
export const selectSearchTreeNavigationGroups = createSelector(
  selectSearchTreeNavigation,
  (tree) => tree?.groups
);

export const selectAllTreeNavigationGroups = createSelector(
  selectSearchTreeNavigationGroups,
  selectTreeNavigationGroups,
  (searchTreeGroups, treeGroups) => [...treeGroups, ...(searchTreeGroups ?? [])]
);

export const selectTreeNavigationSortedGroups = createSelector(
  selectSearchTreeNavigationGroups,
  selectTreeNavigationGroups,
  (searchTreeGroups, treeGroups) =>
    searchTreeGroups || treeGroups
      ? GroupHelper.sortGroupsAndChildGroupsAlphabetically(searchTreeGroups ?? treeGroups)
      : undefined
);

export const selectAllowLoadSites = createSelector(selectCore, (state) => state.allowLoadSites);

export const selectHasNoEnvironments = createSelector(
  selectCore,
  (state) => state.hasNoEnvironments
);

export const selectSelectedGroup = createSelector(selectCore, (state) => state.selectedGroup);

export const selectSelectedGroupId = createSelector(selectCore, (state) => state.selectedGroup?.id);

export const selectGroupSearchStatus = createSelector(
  selectCore,
  (state) => state.groupSearchRunning
);

export const selectGroupsExist = createSelector(
  selectGroups,
  (groups) => !!groups && !!groups.length
);

export const selectAllGroups = createSelector(selectGroups, (groups) =>
  groups ? GroupMapper.flatAllGroups(groups) : []
);

export const selectShowNavigationMenu = createSelector(
  selectCore,
  (state) => state.showNavigationMenu
);
export const selectSubNavigationSelectedHeadline = createSelector(
  selectCore,
  (state) => state.subNavigationSelectedHeadline
);

export const selectShouldShowHeadlineContainer = createSelector(
  selectShowEnvironmentContainer,
  selectShowGlobalYearSelection,
  (showEnvironmentContainer, selectShowGlobalYearSelection) =>
    showEnvironmentContainer || selectShowGlobalYearSelection
);

/**
 * On the dashboard & portfolio screen, data is always shown for three years. E.g., if the year 2020
 * is selected, also the data for 2019 and 2018 is shown. But if there is no data for 2018, 2021
 * should be shown instead. This method determines the adjacent years of the selected year. If no
 * selected year is passed, the most recent three years are returned.
 */
const calcYearsRange = (yearsWithData: number[], selectedYear?: number) => {
  if (!selectedYear) {
    return yearsWithData.length <= 3
      ? yearsWithData
      : yearsWithData.slice(yearsWithData.length - 3, yearsWithData.length);
  }
  const index = yearsWithData.findIndex((item) => item === selectedYear);
  const start = index - 2 >= 0 ? index - 2 : 0;
  let end = index < 0 ? 0 : index + 1;
  if (start === 0) {
    end = 3;
  }
  return yearsWithData.slice(start, end);
};

/** @param selectedYear Selected year */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const selectAdjacentOrDefaultYears = (selectedYear?: number) =>
  createSelector(selectYearsWithData, (yearsWithData: number[]) =>
    calcYearsRange(yearsWithData, selectedYear)
  );

export const selectAdjacentYearsDependsOnGlobalSelectedYear = createSelector(
  selectGlobalSelectedYear,
  selectYearsWithData,
  (selectedYear, yearsWithData: number[]) => calcYearsRange(yearsWithData, selectedYear)
);

export const selectChartYearFilterRange = createSelector(
  selectAdjacentYearsDependsOnGlobalSelectedYear,
  (years: number[]) =>
    ({
      from: `${years[0]}`,
      until: `${years[years.length - 1]}`,
    } as FilterDateRange)
);

export const selectSelectedLanguage = createSelector(
  selectCore,
  (state) => state.userSettings?.language
);

export const selectSelectedSite = createSelector(selectCore, (state) => state.selectedSite);

export const selectIsSite = createSelector(selectSelectedSite, (state) => !!state?.id);

export const selectSelectedGroupOrSite = createSelector(
  selectCore,
  (state) => state?.selectedSiteOrGroup
);
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const selectSpecificGroup = (groupId: number) =>
  createSelector(selectAllGroups, (groups) => groups.find((group) => group.id === groupId));
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const selectTreeNavigationSpecificGroup = (groupId: number) =>
  createSelector(selectTreeNavigationGroups, (groups) =>
    GroupMapper.flatAllGroups(groups).find((group) => group.id === groupId)
  );
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
// New Implementation for TreeNavigation
export const selectTreeNavigationUnassignedSitesGroup = createSelector(
  selectTreeNavigation,
  (state) => state.unassignedSitesGroup
);

export const selectSearchTreeNavigationUnassignedSitesGroup = createSelector(
  selectSearchTreeNavigation,
  (state) => state?.unassignedSitesGroup
);

export const selectUnassignedSitesGroup = createSelector(
  selectSearchTreeNavigationUnassignedSitesGroup,
  selectTreeNavigationUnassignedSitesGroup,
  (unassignedSearchTree, unassignedTree) => unassignedSearchTree ?? unassignedTree
);

export const selectUnassignedSitesGroupSortedSites = createSelector(
  selectUnassignedSitesGroup,
  (unassignedSitesGroup) => ({
    ...unassignedSitesGroup,
    sites: unassignedSitesGroup?.sites
      ? GroupHelper.sortSitesAlphabetically(unassignedSitesGroup?.sites)
      : undefined,
  })
);

// End New

export const selectEnvironmentSettings = createSelector(
  selectCore,
  (state) => state.environmentSettings
);

export const selectIsCustomYearSelected = createSelector(
  selectEnvironmentSettings,
  (state) => state?.type === 'CUSTOM_REPORT_YEAR'
);

export const selectCustomYearStartDate = createSelector(
  selectEnvironmentSettings,
  (state) => state?.annualReportStartDate
);

export const selectEnvironmentSettingsType = createSelector(
  selectCore,
  (state) => state.environmentSettings?.type
);

export const selectAllTags = createSelector(selectCore, (state) => state.tags);
