import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

import { Notification } from './notification.interface';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private notifications$: ReplaySubject<Notification> = new ReplaySubject<Notification>();

  public addNotification(notification: Notification): void {
    if (notification) {
      this.notifications$.next(notification);
    } else {
      // LoggerService.warn('cannot add notification: ', notification);
    }
  }

  public getNotification$(): Observable<Notification> {
    return this.notifications$.asObservable();
  }
}
