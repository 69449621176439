import { Injectable } from '@angular/core';
import { ChartItem } from '../chart.interface';
import { FilterDateRange } from '../../filter/filter-tab.interface';
import { ChartConfiguration } from 'chart.js';
import { HeatingDisplayAllExcludeChilds } from 'shared/models/heating.type';
import { ChartHelperService } from '../chart-helper.service';
import { ALL_LABEL, heatingValueLabel } from 'shared/constants';
import { ChartHelper } from 'shared/helper/chart-helper';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class BarHorizontalChartService {
  constructor(
    private chartHelperService: ChartHelperService,
    private translateService: TranslateService
  ) {}
  getBarChartData(
    data: ChartItem[],
    filterBy: string,
    selectedYear: FilterDateRange
  ): ChartConfiguration['data'] {
    try {
      const datasets: any[] = [];
      let selectedYearlabels: string[] = [];

      if (data && selectedYear) {
        selectedYearlabels = data[0]?.data
          .filter((item) => item.year >= selectedYear.from && item.year <= selectedYear.until)
          .map((item) => item.year);

        let displayData = data?.filter((item) => item.value === filterBy || filterBy === ALL_LABEL);
        if (filterBy === ALL_LABEL) {
          displayData = data?.filter(
            (item) =>
              HeatingDisplayAllExcludeChilds.includes(item.value || item.label) &&
              !ChartHelper.areAllDataValuesUndefined(item.data) &&
              item.value !== heatingValueLabel
          );
        }

        displayData?.forEach((item) => {
          datasets.push(
            this.chartHelperService.getDataSetSkeleton(
              ChartHelper.getValueByFilterDates(item.data, selectedYearlabels),
              this.translateService.instant(item.label || item.value || '') as string,
              item.color
            )
          );
        });
      }

      return {
        datasets,
        labels: selectedYearlabels,
      };
    } catch (e) {
      console.error('getBarChartData', e);
      return {
        datasets: [],
        labels: [],
      };
    }
  }
}
