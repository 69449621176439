import { ChangeDetectionStrategy, Component, forwardRef, OnDestroy, OnInit } from '@angular/core';
import {
  selectGlobalSelectedYear,
  selectYearsWithDataSortedDesc,
} from 'core/+state/core.selectors';
import { Store } from '@ngrx/store';
import { RootState } from 'app/+state/app.reducer';
import { filter, Subject, takeUntil } from 'rxjs';
import { updateGlobalSelectedYear } from 'core/+state/core.actions';
import { MatButton } from '@angular/material/button';
import { MatMenu, MatMenuContent, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';
import { AsyncPipe, NgForOf, NgIf } from '@angular/common';
import { FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { ButtonComponent } from 'shared/ui/basic/button/button.component';

@Component({
  selector: 'ista-daytona-global-year-selection',
  templateUrl: 'global-year-selection.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatButton,
    MatMenuTrigger,
    MatMenu,
    MatMenuItem,
    MatIconModule,
    NgForOf,
    FormsModule,
    AsyncPipe,
    NgIf,
    MatMenuContent,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      // eslint-disable-next-line no-use-before-define
      useExisting: forwardRef(() => ButtonComponent),
      multi: true,
    },
  ],
  standalone: true,
})
export class GlobalYearSelectionComponent implements OnInit, OnDestroy {
  selectYearsWithDataSortedDesc$ = this.ngrxStore.select(selectYearsWithDataSortedDesc);
  selectedYear?: number = undefined; // for default Value, with async pipe doesn't work
  protected chevronType = 'arrow_drop_down';
  private destroy$ = new Subject<void>();

  constructor(private readonly ngrxStore: Store<RootState>) {}

  ngOnInit(): void {
    this.ngrxStore
      .select(selectGlobalSelectedYear)
      .pipe(
        filter((selectedYear) => !!selectedYear),
        takeUntil(this.destroy$)
      )
      .subscribe((selectedYear) => (this.selectedYear = selectedYear));
  }

  onSelectYearChange({ value }: { value: number }): void {
    this.ngrxStore.dispatch(
      updateGlobalSelectedYear({
        globalSelectedYear: value,
      })
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
