<mat-button-toggle-group name="selection" [value]="selections[0].value">
  @for(selection of selections; track selection) {
    <mat-button-toggle
      [value]="selection.value"
      (click)="setSelectedTab(selection)"
    >
      {{ selection.label | translate }}
    </mat-button-toggle>
  }
</mat-button-toggle-group>
