import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { RouterStateSnapshot, TitleStrategy } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

// updateTitle will not update the page title if the url contains an element of this
const DISABLED_URLS = ['/portfolio/group', '/portfolio/site'];

export const BASE_PAGE_TITLE = 'ESG-Manager';

@Injectable({
  providedIn: 'root',
})
export class TitleStrategyService extends TitleStrategy {
  constructor(private readonly _titleService: Title, private translateService: TranslateService) {
    super();
  }

  public override updateTitle(routerState: RouterStateSnapshot): void {
    const title = this.buildTitle(routerState) || '';
    if (title && !DISABLED_URLS.some((url) => routerState.url.includes(url))) {
      this.translateService.stream(title).subscribe((translatedTitle: string) => {
        this._titleService.setTitle(`${BASE_PAGE_TITLE} - ${translatedTitle}`);
      });
    }
  }
}
