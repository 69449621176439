import { Injectable } from '@angular/core';
import { ComponentStore, OnStoreInit } from '@ngrx/component-store';
import { BreadcrumbItem } from '@ista-ui/angular';
import {
  selectRouteBreadcrumbTitle,
  selectRouteUrlWithoutQueryParams,
} from 'app/+state/router/reduced-route.selectors';
import { Store } from '@ngrx/store';
import { RootState } from 'app/+state/app.reducer';
import { combineLatest, distinctUntilChanged, filter, mergeMap, Observable, takeUntil } from 'rxjs';
import { BREADCRUMB_MAX_WIDTH } from 'shared/ui/breadcrumb-navigation/constants';
import { TranslateService } from '@ngx-translate/core';

export interface BreadCrumbsState {
  items: BreadcrumbItem[];
  scrollWidth: number;
}

@Injectable()
export class BreadcrumbStore extends ComponentStore<BreadCrumbsState> implements OnStoreInit {
  readonly addBreadcrumbItems = this.updater(
    (state: BreadCrumbsState, items: BreadcrumbItem[]) => ({
      ...state,
      items: [...state.items, ...items.filter((item) => item.title !== undefined)],
    })
  );

  readonly resetToRootAndAddItems = this.updater(
    (state: BreadCrumbsState, items: BreadcrumbItem[]) => {
      const rootItem = state.items.length ? state.items[0] : undefined;
      const filteredItems = items.filter((item) => item.title !== undefined);
      return {
        ...state,
        items: rootItem ? [rootItem, ...filteredItems] : [...filteredItems],
      };
    }
  );

  readonly updateScrollWidth = this.updater((state, scrollWidth: number) => ({
    ...state,
    scrollWidth,
  }));

  readonly setBreadcrumbItems = this.updater(
    (state: BreadCrumbsState, items: BreadcrumbItem[]) => ({
      ...state,
      items: (state.items = items),
    })
  );

  readonly selectBreadcrumbItems$ = this.select((state) => state.items);

  readonly selectIsScrollVisible$ = this.select(
    (state) => state.scrollWidth > BREADCRUMB_MAX_WIDTH,
    {
      equal: (a, b) => a === b,
      debounce: true,
    }
  );

  constructor(
    private readonly ngrxStore: Store<RootState>,
    private translateService: TranslateService
  ) {
    super({ items: [], scrollWidth: 0 });
  }

  getBreadcrumbItems(): BreadcrumbItem[] {
    return this.get().items;
  }

  ngrxOnStoreInit(): void {
    combineLatest([
      this.ngrxStore.select(selectRouteBreadcrumbTitle).pipe(
        filter(Boolean),
        mergeMap((title) => this.translateService.stream(title) as Observable<string>)
      ),
      this.ngrxStore.select(selectRouteUrlWithoutQueryParams),
    ])
      .pipe(
        distinctUntilChanged(
          ([oldTitle, oldUrl], [newTitle, newUrl]) => newTitle === oldTitle && oldUrl === newUrl
        ),
        takeUntil(this.destroy$)
      )
      .subscribe(([title, url]) => {
        const route = url.split('/')[1];
        this.setBreadcrumbItems([{ title, route: `${route}` }]);
      });
  }
}
