import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class CustomIconsService {
  constructor(private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {}

  registerCustomIcons(): void {
    this.matIconRegistry.addSvgIcon(
      'circle_cost',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/rebranding/cost.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'table_filter',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/table-filter.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'emissions',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/rebranding/emissions.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'money_graph',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/rebranding/money-graph-bar.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'heating',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/rebranding/heating.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'building',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/rebranding/building.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'area',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/rebranding/area.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'door',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/rebranding/door.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'waste',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/rebranding/waste.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'water',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/rebranding/water.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'electricity',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/rebranding/electricity.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'buildings',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/rebranding/site-building.svg'
      )
    );

    this.matIconRegistry.addSvgIcon(
      'masterdata',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/rebranding/masterdata.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'logo',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/rebranding/ista-logo.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'logo_small',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/rebranding/ista-logo-small.svg'
      )
    );

    this.matIconRegistry.addSvgIcon(
      'finance_chart',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/rebranding/finance-chart.svg'
      )
    );

    this.matIconRegistry.addSvgIcon(
      'report',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/rebranding/report.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'envelop',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/rebranding/envelop.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'help',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/rebranding/help.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'settings',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/rebranding/settings.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'logout',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/rebranding/logout.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'tags',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/rebranding/tags.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'marker',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/rebranding/marker.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'glossar',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/rebranding/glossar.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'add_circle',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/rebranding/add-circle.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'shopping_building',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/rebranding/shopping-building.svg'
      )
    );

    this.matIconRegistry.addSvgIcon(
      'dashboard',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/rebranding/dashboard.svg')
    );
  }
}
