import { Component, Input, TemplateRef } from '@angular/core';
import { TooltipPosition, TooltipTheme } from 'shared/ui/basic/tooltip/interface';

@Component({
  selector: 'ista-daytona-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
})
export class TooltipComponent {
  /**
   * This provides finer control on the content to be visible on the tooltip This template will be
   * injected in ToolTipRenderer directive in the consumer template <ng-template #template>
   * content..... </ng-template>
   */
  @Input() contentTemplate!: TemplateRef<any>;
  @Input() context: any;
  @Input() theme: TooltipTheme = 'dark';
  @Input() position: TooltipPosition = 'top';
}
