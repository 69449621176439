@if(data) {
  <div [class.p8-10]="data.unitText" class="flex flex-col p-6 border rounded border-base-200 relative h-full">
    <div class="inner_content">
      <div class="header">
        <ista-daytona-card-headline [label]="data.header" data-cy="bar-card--header"></ista-daytona-card-headline>
      </div>
      @if (data.infoText) {
        <div class="mt-4">
          <p
            [innerHTML]="data.infoText || '' | translate"
            class="infotext text-base text-base-700 font-normal"
            data-cy="bar-card--infoText"
          ></p>
        </div>
      }
      <div #barScreen class="bar-container mt-4 mb-10">
        @if(filteredBarItems.length) {
          <div class="relative h-[215px] bg-white p-4 border border-base-200">
            @for(scale of scaleData.slice(0, -1); let index = $index; track scale) {
              <div
                [class.border-l-0]="index === 0"
                [style.left]="getPosition(index)" class="absolute w-[1px] top-0 border-l border-l-base-200 z-10 h-full"
              ></div>
            }
            @for(bar of filteredBarItems; let first = $first; let last = $last; let index = $index; track bar) {
              @if(bar.value) {
                @if(bar.value.value | formatNumber : bar.value.unit; as transformedValueItem) {
                  <div
                    [class.hover:text-primary]="!!deepLinkingTargetId"
                    [customParamsValues]="deepLinkingCustomParams"
                    [redirectUrl]="deepLinkingRedirectBaseUrl + bar.id"
                    [style.background]="color"
                    [style.top.px]="getBarTop(index)"
                    [style.width]="getBarWidth(bar)"
                    [targetId]="deepLinkingTargetId"
                    [title]="bar.label"
                    class="h-6 absolute left-0 z-20 text-base-900"
                    data-cy="bar-card--item"
                    deepLinkingSender
                    [matTooltipClass]="['whitespace-pre-line']"
                    [matTooltip]="getToolTipValue(bar.label, transformedValueItem)"
                    matTooltipPosition="after"
                  >
                <span
                  [innerText]="bar.label"
                  [style.width]="offsetWidth"
                  class="ml-3 text-base font-extrabold inline-block truncate"
                ></span>
                  </div>
                }
              }
            }
          </div>
          <div class="mt-2 flex relative justify-center mb-6">
            @for(scale of scaleData; let index = $index; track scale) {
              <div
                [innerText]="scale"
                [style.left]="getScaleLabelPosition(index)"
                class="text-sm font-semibold text-base-500 h-6 absolute"
                data-cy="bar-card--scale"
              ></div>
            }
          </div>
        }
      </div>

    </div>
    @if (data.unitText) {
      <span class="block text-right mt-auto text-base-500 text-sm font-extrabold">
      {{ data.unitText | translate }}
    </span>
    }
  </div>
}
