import {
  afterRender,
  AfterRenderPhase,
  AfterRenderRef,
  AfterViewInit,
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  ElementRef,
  inject,
  Input,
  OnDestroy,
} from '@angular/core';
import { BreadcrumbComponent, BreadcrumbItem, DividerStyle } from '@ista-ui/angular';
import { DirectivesModule } from 'shared/directives/directives.module';
import { AsyncPipe } from '@angular/common';
import { BreadcrumbStore } from 'shared/ui/breadcrumb-navigation/breadcrumb.store';
import { delay } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'ista-daytona-breadcrumb-navigation',
  standalone: true,
  imports: [BreadcrumbComponent, DirectivesModule, AsyncPipe],
  templateUrl: './breadcrumb-navigation.component.html',
  styleUrls: ['./breadcrumb-navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BreadcrumbNavigationComponent implements AfterViewInit, OnDestroy {
  @Input({ transform: booleanAttribute }) isSticky = false;
  selectBreadcrumbItems$ = this.breadcrumbsStore.selectBreadcrumbItems$;
  selectIsScrollVisible$ = this.breadcrumbsStore.selectIsScrollVisible$;
  afterRenderRef?: AfterRenderRef;
  protected readonly DividerStyle = DividerStyle;
  private destroyRef = inject(DestroyRef);

  constructor(private breadcrumbsStore: BreadcrumbStore, private el: ElementRef<HTMLDivElement>) {
    this.afterRenderRef = afterRender(
      () => {
        const items = this.breadcrumbsStore.getBreadcrumbItems();
        this.addDataCyAttributes(items);
      },
      { phase: AfterRenderPhase.Write }
    );
  }

  ngOnDestroy(): void {
    if (this.afterRenderRef) {
      this.afterRenderRef.destroy();
    }
  }

  ngAfterViewInit(): void {
    this.selectBreadcrumbItems$
      .pipe(delay(100), takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.setScrollToEnd());
  }

  private addDataCyAttributes(items: BreadcrumbItem[]): void {
    if (this.el) {
      const elements: HTMLDivElement[] = Array.from(
        this.el.nativeElement.querySelectorAll('.breadcrumb-comp .overflow > div.items-center')
      );
      items.forEach((item, index) => {
        if (elements[index]) {
          elements[index].dataset['cy'] = item.title;
        }
      });
    }
  }

  private setScrollToEnd(): void {
    if (this.el) {
      const overflowWrapper = this.el.nativeElement.querySelector(
        '.breadcrumb-comp .overflow'
      ) as HTMLDivElement;
      if (overflowWrapper) {
        this.breadcrumbsStore.updateScrollWidth(overflowWrapper.scrollWidth);
        overflowWrapper.scrollLeft = overflowWrapper.scrollWidth;
      }
    }
  }
}
