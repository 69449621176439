/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/member-ordering */
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  ViewContainerRef,
} from '@angular/core';
import { fromEvent, merge, Observable, Subject, Subscription } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { DropdownPanel } from './dropdown-panel.interface';

@Directive({
  selector: '[dropdownTriggerFor]',
})
export class DropdownDirective implements OnDestroy {
  @Input() dropdownTriggerFor!: DropdownPanel;
  @Input() onDestroyCallback?: () => void;
  @Input() avoidClosing = false;

  @Input() set forceClosing(status: boolean) {
    if (status) {
      this.destroyDropdown();
    }
  }

  @HostListener('click', ['$event'])
  toggleDropdown(event: MouseEvent): void {
    event.stopPropagation(); // Verhindert das Auslösen von document:click Event
    if (!this.isDropdownOpen) {
      this.openDropdown();
    } else if (this.isDropdownOpen && this.avoidClosing) {
      this.destroyDropdown();
    }
  }

  private isDropdownOpen = false;
  private overlayRef!: OverlayRef;
  private dropdownClosingActionsSub = Subscription.EMPTY;
  private escapeKeySubscription: Subscription = Subscription.EMPTY;
  private boundDocumentClickListener = this.onDocumentClick.bind(this);
  private onDestroy = new Subject<void>();

  constructor(
    private overlay: Overlay,
    private elementRef: ElementRef,
    private viewContainerRef: ViewContainerRef
  ) {}

  ngOnDestroy(): void {
    if (this.overlayRef) {
      this.overlayRef.dispose();
    }
    this.onDestroy.next();
    this.onDestroy.complete();
    document.removeEventListener('click', this.boundDocumentClickListener);
  }

  openDropdown(): void {
    this.isDropdownOpen = true;
    this.overlayRef = this.overlay.create({
      hasBackdrop: true,
      backdropClass: 'cdk-overlay-transparent-backdrop',
      scrollStrategy: this.overlay.scrollStrategies.close(),
      positionStrategy: this.overlay
        .position()
        .flexibleConnectedTo(this.elementRef)
        .withPositions([
          {
            originX: 'center',
            originY: 'bottom',
            overlayX: 'center',
            overlayY: 'top',
            offsetY: 8,
          },
        ]),
    });

    const templatePortal = new TemplatePortal(
      this.dropdownTriggerFor.templateRef,
      this.viewContainerRef
    );
    this.overlayRef.attach(templatePortal);

    this.dropdownClosingActionsSub = this.dropdownClosingActions().subscribe(() => {
      if (!this.avoidClosing) {
        this.destroyDropdown();
      }
    });

    this.escapeKeySubscription = fromEvent<KeyboardEvent>(document, 'keydown')
      .pipe(
        filter((event) => event.key === 'Escape'),
        takeUntil(this.onDestroy)
      )
      .subscribe(() => this.destroyDropdown());

    // Click event listener for document to close the dropdown when clicking outside
    document.addEventListener('click', this.boundDocumentClickListener);
  }

  private dropdownClosingActions(): Observable<unknown> {
    const backdropClick$ = this.overlayRef.backdropClick();
    const detachment$ = this.overlayRef.detachments();
    const dropdownClosed = this.dropdownTriggerFor.closed;

    return merge(backdropClick$, detachment$, dropdownClosed);
  }

  private onDocumentClick(event: MouseEvent): void {
    const target = event.target as HTMLElement;
    const clickedInside =
      this.elementRef.nativeElement.contains(target) ||
      this.overlayRef.overlayElement.contains(target);
    if (!clickedInside) {
      this.destroyDropdown();
    }
  }

  private destroyDropdown(): void {
    if (this.onDestroyCallback) {
      this.onDestroyCallback();
    }

    if (!this.overlayRef || !this.isDropdownOpen) {
      return;
    }

    this.dropdownClosingActionsSub.unsubscribe();
    this.escapeKeySubscription.unsubscribe();
    this.isDropdownOpen = false;
    this.overlayRef.detach();

    // Remove the document click listener when the dropdown is destroyed
    document.removeEventListener('click', this.boundDocumentClickListener);
  }
}
