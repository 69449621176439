import { PortfolioCard } from 'shared/ui/portfolio-card/portfolio-card.interface';
import { IconHelper } from 'shared/helper/icon-helper';
import { InfoIconCard } from 'shared/ui/info-icon-card/info-icon-card.interface';
import { CustomIcon } from 'shared/interfaces/custom-icon.interface';

export const disableableNavigationItems = ['dashboard', 'portfolio', 'benchmarking', 'reporting'];

export const sitePortfolioData: PortfolioCard[] = [
  {
    header: {
      label: 'dashboard.object',
      icon: {
        text: 'Objekte',
        url: IconHelper.get('building') || '',
      },
    },
    data: [],
  },
  {
    header: {
      label: 'dashboard.units',
      icon: {
        text: 'Nutzeinheiten',
        url: IconHelper.get('room') || '',
      },
    },
    data: [],
  },
  {
    header: {
      label: 'dashboard.area',
      icon: {
        text: 'Fläche',
        url: IconHelper.get('utilityUnit') || '',
      },
    },
    data: [],
  },
];

export const BuildingCustomIcon: CustomIcon = {
  name: 'building',
  isSvgIcon: true,
  colorClass: 'fill-base-700',
};
export const UnitsCustomIcon: CustomIcon = {
  name: 'door',
  isSvgIcon: true,
  colorClass: 'fill-base-700',
};
export const AreaCustomIcon: CustomIcon = {
  name: 'area',
  isSvgIcon: true,
  colorClass: 'fill-base-700',
};

export const heatingValueLabel = 'heatingAndHotWater';

export const groupPortfolioDataInfoCard: InfoIconCard[] = [
  {
    label: 'dashboard.object',
    icon: BuildingCustomIcon,
    value: 0,
  },
  {
    label: 'dashboard.units',
    icon: UnitsCustomIcon,
    value: 0,
  },
  {
    label: 'dashboard.area',
    icon: AreaCustomIcon,
    value: 0,
  },
];
export const sitePortfolioDataInfoCard: InfoIconCard[] = [
  {
    label: 'dashboard.units',
    icon: UnitsCustomIcon,
    value: 0,
  },
  {
    label: 'dashboard.area',
    icon: AreaCustomIcon,
    value: 0,
  },
];

export const GROUP_NAME_EXISTS_ERROR_KEY = 'groupNameExists';

export const ReportingConsumptionTabParamLabel = 'consumption';

export const ALL_LABEL = 'all';
