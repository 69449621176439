import { Component } from '@angular/core';

import { UtilHelper } from 'shared/helper/util-helper';

@Component({
  selector: 'ista-daytona-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss'],
})
export class PageNotFoundComponent {
  constructor() {
    UtilHelper.scrollToTop();
  }
}
