import { UtilHelper } from './util-helper';
import { Unit, UnitFactor, UnitMappingConfig, UnitWithPrefix } from '../models/unit.enum';

export const factorSequence = [
  UnitFactor.ONE,
  UnitFactor.K,
  UnitFactor.M,
  UnitFactor.G,
  UnitFactor.T,
];

export const getNextFactor = (factor: UnitFactor): UnitFactor => {
  let findIndex = factorSequence.findIndex((item) => item === factor);
  if (findIndex >= factorSequence.length) {
    return factor;
  }

  return factorSequence[findIndex++];
};

export const unitMapping: UnitMappingConfig[] = [
  {
    unit: Unit.CBM,
    maxDisplayedDigits: UtilHelper.getNumberCount(UnitFactor.G),
    mapping: [
      { factor: UnitFactor.ONE, unitWithPrefix: UnitWithPrefix.CBM },
      { factor: UnitFactor.K, unitWithPrefix: UnitWithPrefix.CBM },
      { factor: UnitFactor.M, unitWithPrefix: UnitWithPrefix.CBM },
      { factor: UnitFactor.G, unitWithPrefix: UnitWithPrefix.CBM_K },
      { factor: UnitFactor.T, unitWithPrefix: UnitWithPrefix.CBM_K },
    ],
    unitWithPrefixSequence: [UnitWithPrefix.CBM, UnitWithPrefix.CBM_K],
    rootUnitWithPrefix: UnitWithPrefix.CBM,
  },
  {
    unit: Unit.CBM_PER_SQM,
    maxDisplayedDigits: UtilHelper.getNumberCount(UnitFactor.G),
    mapping: [
      {
        factor: UnitFactor.ONE,
        unitWithPrefix: UnitWithPrefix.CBM_PER_SQM,
      },
      {
        factor: UnitFactor.K,
        unitWithPrefix: UnitWithPrefix.CBM_PER_SQM,
      },
      {
        factor: UnitFactor.M,
        unitWithPrefix: UnitWithPrefix.CBM_PER_SQM,
      },
      {
        factor: UnitFactor.G,
        unitWithPrefix: UnitWithPrefix.CBM_K_PER_SQM,
      },
      {
        factor: UnitFactor.T,
        unitWithPrefix: UnitWithPrefix.CBM_K_PER_SQM,
      },
    ],
    unitWithPrefixSequence: [UnitWithPrefix.CBM_PER_SQM, UnitWithPrefix.CBM_K_PER_SQM],
    rootUnitWithPrefix: UnitWithPrefix.CBM_PER_SQM,
  },
  {
    unit: Unit.SQM,
    maxDisplayedDigits: UtilHelper.getNumberCount(UnitFactor.K),
    mapping: [
      { factor: UnitFactor.ONE, unitWithPrefix: UnitWithPrefix.SQM },
      { factor: UnitFactor.K, unitWithPrefix: UnitWithPrefix.SQM_K },
      { factor: UnitFactor.M, unitWithPrefix: UnitWithPrefix.SQM_M },
    ],
    unitWithPrefixSequence: [UnitWithPrefix.SQM, UnitWithPrefix.SQM_K],
    rootUnitWithPrefix: UnitWithPrefix.SQM,
  },
  {
    unit: Unit.EUR,
    maxDisplayedDigits: UtilHelper.getNumberCount(UnitFactor.K),
    mapping: [
      { factor: UnitFactor.ONE, unitWithPrefix: UnitWithPrefix.EUR },
      { factor: UnitFactor.K, unitWithPrefix: UnitWithPrefix.EURO_K },
      { factor: UnitFactor.M, unitWithPrefix: UnitWithPrefix.EURO_M },
      { factor: UnitFactor.G, unitWithPrefix: UnitWithPrefix.EURO_G },
      { factor: UnitFactor.T, unitWithPrefix: UnitWithPrefix.EURO_T },
    ],
    unitWithPrefixSequence: [
      UnitWithPrefix.EUR,
      UnitWithPrefix.EURO_K,
      UnitWithPrefix.EURO_M,
      UnitWithPrefix.EURO_G,
      UnitWithPrefix.EURO_T,
    ],
    rootUnitWithPrefix: UnitWithPrefix.EUR,
  },
  {
    unit: Unit.KWH,
    maxDisplayedDigits: UtilHelper.getNumberCount(UnitFactor.K),
    mapping: [
      { factor: UnitFactor.ONE, unitWithPrefix: UnitWithPrefix.KWH },
      { factor: UnitFactor.K, unitWithPrefix: UnitWithPrefix.KWH_K },
      { factor: UnitFactor.M, unitWithPrefix: UnitWithPrefix.KWH_M },
    ],
    unitWithPrefixSequence: [UnitWithPrefix.KWH, UnitWithPrefix.KWH_K, UnitWithPrefix.KWH_M],
    rootUnitWithPrefix: UnitWithPrefix.KWH,
  },
  {
    unit: Unit.KWH_PER_SQM, // NEU
    maxDisplayedDigits: UtilHelper.getNumberCount(UnitFactor.K),
    mapping: [
      {
        factor: UnitFactor.ONE,
        unitWithPrefix: UnitWithPrefix.KWH_PER_SQM,
      },
      {
        factor: UnitFactor.K,
        unitWithPrefix: UnitWithPrefix.KWH_K_PER_SQM,
      },
      {
        factor: UnitFactor.M,
        unitWithPrefix: UnitWithPrefix.KWH_M_PER_SQM,
      },
    ],
    unitWithPrefixSequence: [
      UnitWithPrefix.KWH_PER_SQM,
      UnitWithPrefix.KWH_K_PER_SQM,
      UnitWithPrefix.KWH_M_PER_SQM,
    ],
    rootUnitWithPrefix: UnitWithPrefix.KWH_PER_SQM,
  },
  {
    unit: Unit.KG,
    maxDisplayedDigits: UtilHelper.getNumberCount(UnitFactor.K),
    mapping: [
      { factor: UnitFactor.ONE, unitWithPrefix: UnitWithPrefix.KG },
      { factor: UnitFactor.K, unitWithPrefix: UnitWithPrefix.KG_K },
    ],
    unitWithPrefixSequence: [UnitWithPrefix.KG, UnitWithPrefix.KG_K],
    rootUnitWithPrefix: UnitWithPrefix.KG,
  },
  {
    unit: Unit.KG_PER_SQM,
    maxDisplayedDigits: UtilHelper.getNumberCount(UnitFactor.K),
    mapping: [
      {
        factor: UnitFactor.ONE,
        unitWithPrefix: UnitWithPrefix.KG_PER_SQM,
      },
      {
        factor: UnitFactor.K,
        unitWithPrefix: UnitWithPrefix.KG_K_PER_SQM,
      },
      {
        factor: UnitFactor.M,
        unitWithPrefix: UnitWithPrefix.KG_M_PER_SQM,
      },
    ],
    unitWithPrefixSequence: [
      UnitWithPrefix.KG_PER_SQM,
      UnitWithPrefix.KG_K_PER_SQM,
      UnitWithPrefix.KG_M_PER_SQM,
    ],
    rootUnitWithPrefix: UnitWithPrefix.KG_PER_SQM,
  },
  {
    unit: Unit.EMISSION, // NEU 16.03.23
    maxDisplayedDigits: UtilHelper.getNumberCount(UnitFactor.K),
    mapping: [
      { factor: UnitFactor.ONE, unitWithPrefix: UnitWithPrefix.EMISSION },
      { factor: UnitFactor.K, unitWithPrefix: UnitWithPrefix.EMISSION_K },
      { factor: UnitFactor.M, unitWithPrefix: UnitWithPrefix.EMISSION_M },
    ],
    unitWithPrefixSequence: [
      UnitWithPrefix.EMISSION,
      UnitWithPrefix.EMISSION_K,
      UnitWithPrefix.EMISSION_M,
    ],
    rootUnitWithPrefix: UnitWithPrefix.EMISSION,
  },
];
