import { CanDeactivateFn } from '@angular/router';
import { CanSettingsComponentDeactivate } from './interfaces';
import { mergeMap, of, tap } from 'rxjs';

export const UnsavedChangesGuard: CanDeactivateFn<CanSettingsComponentDeactivate> = (
  component: CanSettingsComponentDeactivate
) => {
  if (!component) {
    return of(true);
  }
  return component.hasUnsavedData().pipe(
    mergeMap((hasUnsavedChanges) =>
      hasUnsavedChanges
        ? component
            .showUnsavedDialog()
            .afterClosed()
            .pipe(
              tap((leaveScreen) => {
                if (!leaveScreen) {
                  component.highlightSaveButton();
                }
              })
            )
        : of(true)
    )
  );
};
