import { CanActivateFn } from '@angular/router';
import { Store } from '@ngrx/store';
import { RootState } from 'app/+state/app.reducer';
import { selectEnvironmentHasNoData, selectEnvironmentReadiness } from 'core/+state/core.selectors';
import { inject } from '@angular/core';
import { combineLatest, filter, map } from 'rxjs';


export const emptyEnvironmentGuard: CanActivateFn = () => {
  const ngrxStore = inject(Store<RootState>);
  return combineLatest([ngrxStore.select(selectEnvironmentHasNoData), ngrxStore.select(selectEnvironmentReadiness)]).pipe(
    filter(([hasNoData, envReady]) => !hasNoData && envReady),
    map(() => true),
  );
};
