import { AsyncPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { TagsHttpService } from 'core/tags/tags-http.service';
import { TagsService } from 'core/tags/tags.service';
import { TagsStore } from 'core/tags/tags.store';
import { debounceTime, first, forkJoin, map, Observable, of, Subject, takeUntil, tap } from 'rxjs';
import {
  NAME_EXISTS_ERROR_KEY,
  sortFunction,
  Tag,
  TagForm,
  UnsavedChanges,
} from 'core/tags/interface';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatError, MatFormField } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { TagActiveStateComponent } from 'core/tags/tag-active-state/tag-active-state.component';
import { CustomValidators } from 'core/tags/utils/custom-validators';
import { TranslateModule } from '@ngx-translate/core';
import { PipesModule } from 'shared/pipes/pipes.module';
import { UiModule } from 'shared/ui/ui.module';
import { TagEditOptionsComponent } from 'core/tags/tag-edit-options/tag-edit-options.component';
import { provideComponentStore } from '@ngrx/component-store';

@Component({
  selector: 'ista-daytona-tags',
  templateUrl: 'tags.component.html',
  standalone: true,
  providers: [TagsHttpService, provideComponentStore(TagsStore), TagsService],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatIcon,
    TranslateModule,
    UiModule,
    MatFormField,
    ReactiveFormsModule,
    AsyncPipe,
    PipesModule,
    MatInput,
    TagEditOptionsComponent,
    MatError,
    TagActiveStateComponent,
  ],
})
export class TagsComponent implements OnInit, OnDestroy, UnsavedChanges {
  @Input() enableTagActiveStateAndDisableEditMode = false;
  @Output() tagsActiveStateChange = new EventEmitter<number[]>();
  tags$: Observable<TagForm[]> = this.tagsStore.sortedTagsWithFilteredString$;
  showCreateInputField$: Observable<boolean> = this.tagsStore.showCreateInputField$;
  isDeleteInProgress$: Observable<boolean> = this.tagsStore.isDeleteInProgress$;
  isEditingMode$: Observable<boolean> = this.tagsStore.isEditingMode$;
  isFormInvalid$: Observable<boolean> = this.tagsStore.isFormInvalid$;
  selectHighlightSaveButton$: Observable<boolean> = this.tagsStore.selectHighlightSaveButton$;
  filterString$: Observable<string> = this.tagsStore.filterString$;
  createTagNameCtrl: FormControl = new FormControl(
    '',
    // eslint-disable-next-line @typescript-eslint/unbound-method
    [Validators.required, Validators.maxLength(255)],
    [CustomValidators.createNameAlreadyExists(this.tagsStore)]
  );
  formGroup?: FormGroup;
  private destroy$ = new Subject<void>();

  constructor(
    public tagsService: TagsService,
    public tagsStore: TagsStore,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef
  ) {}

  get tagsFormArray(): FormArray<FormControl> {
    return this.formGroup?.get('tags') as FormArray<FormControl>;
  }

  get isRequiredError(): boolean {
    return this.createTagNameCtrl.hasError('required');
  }

  get isMaxLengthError(): boolean {
    return this.createTagNameCtrl.hasError('maxlength');
  }

  get isNameAlreadyExistError(): boolean {
    return this.createTagNameCtrl.hasError(NAME_EXISTS_ERROR_KEY);
  }

  @Input() set initialCheckedTags(initialChecked: number[]) {
    this.tagsService.updateCheckedTagIds(initialChecked);
  }

  @Input() set sortFunction(sortFunction: sortFunction) {
    this.tagsService.updateSortFunction(sortFunction);
  }

  hasUnsavedData(): Observable<boolean> {
    return forkJoin([
      of(!!this.getValidTouchedTags().length),
      this.isFormInvalid$.pipe(first()),
      this.isEditingMode$.pipe(first()),
    ]).pipe(
      map(([hasUnsavedTags, isInvalid, isEditMode]) => (hasUnsavedTags || isInvalid) && isEditMode)
    );
  }

  highlightSaveButton(): void {
    if (this.getValidTouchedTags().length) {
      this.tagsService.updateHighlightSaveButton(true);
    }
  }

  ngOnInit(): void {
    this.tagsService
      .listenToEnvironmentChangeAndGetAllTags()
      .pipe(takeUntil(this.destroy$))
      .subscribe();

    this.buildFormAndSetTags();
    this.listenToEnableStateChangesAndStatusChanges();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  resetCtrlAndHideCreateInputField(): void {
    this.createTagNameCtrl.reset();
    this.updateShowCreateInputField(false);
  }

  updateShowCreateInputField(showCreateInputField: boolean): void {
    this.tagsService.updateShowCreateInputField(showCreateInputField);
  }

  getValidTouchedTags(): Tag[] {
    return this.tagsFormArray.controls
      .filter((ctrl) => ctrl.touched && ctrl.valid)
      .map((ctrl) => ctrl.value as Tag);
  }

  updateTagsAndIsEditingMode(isEditingMode: boolean): void {
    const editedTags = this.getValidTouchedTags();
    this.tagsService.updateTags(editedTags).subscribe();
    this.updateIsEditingMode(isEditingMode);
    this.tagsService.updateHighlightSaveButton(false);
  }

  updateIsEditingMode(isEditingMode: boolean): void {
    this.tagsService.updateIsEditingMode(isEditingMode);
  }

  deleteTag({ top, left }: { top: number; left: number }, tag: Tag): void {
    this.tagsService.deleteTag(tag, top, left).subscribe();
  }

  onCreateKeyDown(event: KeyboardEvent): void {
    if (event.key === 'Escape') {
      this.updateShowCreateInputField(false);
      this.createTagNameCtrl.reset('');
    } else if (event.key === 'Enter' && this.createTagNameCtrl.valid) {
      this.tagsService.createTag(this.createTagNameCtrl.value as string).subscribe(() => {
        this.createTagNameCtrl.reset('');
        this.tagsService.updateShowCreateInputField(false);
      });
    }
  }

  setFilterString(event: KeyboardEvent): void {
    const value = (event.target as HTMLInputElement).value;
    this.updateSearchString(value);
  }

  clearSearch(): void {
    this.createTagNameCtrl.reset('');
    this.updateSearchString('');
  }

  updateSearchString(value: string): void {
    this.tagsService.updateSearchString(value);
  }

  private listenToEnableStateChangesAndStatusChanges(): void {
    if (this.formGroup) {
      this.formGroup.valueChanges
        .pipe(
          takeUntil(this.destroy$),
          debounceTime(1000),
          map(({ tags }: { tags: TagForm[] }) =>
            tags.filter((tag: TagForm) => tag.checked).map((tag: TagForm) => tag.id)
          ),
          tap((tagsIds) => this.tagsActiveStateChange.emit(tagsIds))
        )
        .subscribe();

      this.formGroup.statusChanges
        .pipe(takeUntil(this.destroy$))
        .subscribe((status) => this.tagsService.updateFormStatus(status));
    }
  }

  private buildFormAndSetTags(): void {
    this.formGroup = this.fb.group({
      tags: this.fb.array([]),
    });
    this.tags$.pipe(debounceTime(200), takeUntil(this.destroy$)).subscribe((tags) => {
      this.tagsFormArray.clear({
        emitEvent: false,
      });
      tags.forEach((tag) =>
        // eslint-disable-next-line @typescript-eslint/unbound-method
        this.tagsFormArray.push(this.fb.control(tag, [Validators.required]), {
          emitEvent: false,
        })
      );
      this.cdr.detectChanges();
    });
  }
}
