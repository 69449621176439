import { Injectable } from '@angular/core';
import { RootState } from 'app/+state/app.reducer';
import { Store } from '@ngrx/store';
import { selectEnvironmentHasNoData } from 'core/+state/core.selectors';
import { map, Observable } from 'rxjs';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { EmptyEnvironmentNotificationComponent } from 'shared/ui/empty-environment-notification/empty-environment-notification.component';
import { Router } from '@angular/router';
import { disableableNavigationItems } from 'shared/constants';

@Injectable({ providedIn: 'root' })
export class EmptyEnvironmentNotificationService {
  overlayRef!: OverlayRef;
  constructor(
    private overlay: Overlay,
    private readonly ngrxStore: Store<RootState>,
    private router: Router
  ) {
    this.overlayRef = this.createOverlayRef();
  }

  createOverlayRef(): OverlayRef {
    return this.overlay.create(this.getOverlayConfig(840, 840));
  }

  open(): void {
    if (!this.router.url.includes('data-upload')) {
      this.router.navigate(['data-upload']);
    }
    this.overlayRef.attach(new ComponentPortal(EmptyEnvironmentNotificationComponent));
    this.overlayRef.backdropClick().subscribe(() => {
      this.overlayRef.detach();
    });
  }

  close(): void {
    this.overlayRef.detach();
  }

  isDisableableItem(itemLink: string): boolean {
    return disableableNavigationItems.includes(itemLink) ;
  }

  isDisabledFromMissingData(itemLink: string): Observable<boolean> {
    return this.ngrxStore
      .select(selectEnvironmentHasNoData)
      .pipe(map((hasNoData: boolean) => hasNoData && this.isDisableableItem(itemLink)));
  }

  private getOverlayConfig(minHeight: number, minWidth: number): OverlayConfig {
    const positionStrategy = this.overlay
      .position()
      .global()
      .centerHorizontally()
      .centerVertically('10%');

    return new OverlayConfig({
      hasBackdrop: true,
      backdropClass: 'overlay-background',
      panelClass: '',
      scrollStrategy: this.overlay.scrollStrategies.block(),
      positionStrategy,
      minHeight,
      minWidth,
    });
  }
}
