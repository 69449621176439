import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ButtonModule } from 'shared/ui/basic/button/button.module';
import { MatIcon } from '@angular/material/icon';
import { PipesModule } from 'shared/pipes/pipes.module';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NotificationType } from 'shared/ui/notification/notification.interface';
import { NotificationService } from 'shared/ui/notification/notification.service';
import { EmptyEnvironmentNotificationService } from 'shared/ui/empty-environment-notification/empty-environment-notification.service';

@Component({
  selector: 'ista-daytona-empty-environment-notification',
  standalone: true,
  imports: [ButtonModule, MatIcon, PipesModule, TranslateModule],
  templateUrl: './empty-environment-notification.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmptyEnvironmentNotificationComponent {
  constructor(
    private translateService: TranslateService,
    private notificationService: NotificationService,
    protected emptyEnvironmentService: EmptyEnvironmentNotificationService
  ) {}

  close(): void {
    this.emptyEnvironmentService.close();
  }

  copyEmailIntoClipBoard(): void {
    navigator.clipboard.writeText(
      this.translateService.instant('no-environment-overlay.esg-email') as string
    );

    this.notificationService.addNotification({
      headline: this.translateService.instant('no-environment-overlay.notification') as string,
      type: NotificationType.CONFIRMATION,
    });
  }
}
