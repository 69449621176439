import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NgChartsModule } from 'ng2-charts';
import { PipesModule } from 'shared/pipes/pipes.module';
import { LabelModule } from 'shared/ui/basic/label/label.module';
import { LoaderModule } from 'shared/ui/basic/loader/loader.module';
import { ValueUnitModule } from 'shared/ui/basic/value-unit/value-unit.module';

import { BasicModule } from '../basic/basic.module';
import { BalanceChartComponent } from './balance-chart/balance-chart.component';
import { BarChartComponent } from './bar-chart/bar-chart.component';
import { BarHorizontalChartMiniComponent } from './bar-horizontal-chart-mini/bar-horizontal-chart-mini.component';
import { BarHorizontalChartComponent } from './bar-horizontal-chart/bar-horizontal-chart.component';
import { BarHorizontalMultiChartComponent } from './bar-horizontal-multi-chart/bar-horizontal-multi-chart.component';
import { BarHorizontalStackedChartComponent } from './bar-horizontal-stacked-chart/bar-horizontal-stacked-chart.component';
import { BenchmarkingChartComponent } from './benchmarking-chart/benchmarking-chart.component';
import { ChartLegendComponent } from './chart-legend/chart-legend.component';
import { DistributionChartComponent } from './distribution-chart/distribution-chart.component';
import { DoughnutChartPercentComponent } from './doughnut-chart-percent/doughnut-chart-percent.component';
import { DoughnutChartSimpleComponent } from './doughnut-chart-simple/doughnut-chart-simple.component';
import { DoughnutChartComponent } from './doughnut-chart/doughnut-chart.component';
import { LineChartComponent } from './line-chart/line-chart.component';
import { MatIcon } from '@angular/material/icon';
import { IconValueLabelComponent } from 'shared/ui/icon-value-label/icon-value-label.component';
import { IconModule } from 'shared/ui/basic/icon/icon.module';

const imExport = [
  LineChartComponent,
  DoughnutChartComponent,
  BarChartComponent,
  BalanceChartComponent,
  BarHorizontalChartComponent,
  DistributionChartComponent,
  BenchmarkingChartComponent,
  BarHorizontalMultiChartComponent,
  ChartLegendComponent,
  DoughnutChartPercentComponent,
  BarHorizontalStackedChartComponent,
  DoughnutChartSimpleComponent,
  BarHorizontalChartMiniComponent,
];

@NgModule({
  imports: [
    CommonModule,
    BasicModule,
    TranslateModule,
    PipesModule,
    NgChartsModule,
    LoaderModule,
    LabelModule,
    ValueUnitModule,
    MatIcon,
    IconValueLabelComponent,
    IconModule,
  ],
  declarations: [...imExport],
  exports: [...imExport],
})
export class ChartModule {}
