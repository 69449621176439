import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { EnvironmentComponent } from './environment.component';
import { EnvironmentContainerComponent } from './environment-container.component';
import { TranslateModule } from '@ngx-translate/core';
import { EnvironmentDropdownPipe } from 'shared/pipes/environment-dropdown.pipe';
import { EnvironmentSelectComponent } from 'core/environment/environment-select/environment-select.component';
import { PipesModule } from 'shared/pipes/pipes.module';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    TranslateModule,
    EnvironmentDropdownPipe,
    EnvironmentSelectComponent,
    PipesModule,
  ],
  declarations: [EnvironmentContainerComponent, EnvironmentComponent],
  exports: [EnvironmentContainerComponent],
  providers: [],
})
export class EnvironmentModule {}
