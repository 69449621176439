import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { BasicModule } from '../basic/basic.module';
import { NavigationComponent } from './navigation.component';
import { MatIconModule } from '@angular/material/icon';
import { LinkWithGlobalQueryParamsDirective } from 'shared/directives/link-with-global-query-params.directive';

@NgModule({
  imports: [
    CommonModule,
    BasicModule,
    TranslateModule,
    RouterModule,
    MatIconModule,
    LinkWithGlobalQueryParamsDirective,
  ],
  declarations: [NavigationComponent],
  exports: [NavigationComponent],
})
export class NavigationModule {}
