import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import {
  selectHideSelectedPeriodInfo,
  selectShowEnvironmentContainer,
  selectShowFooterWave,
  selectShowGlobalYearSelection,
  selectShowSubNavigationMenu,
} from 'app/+state/router/reduced-route.selectors';
import {
  selectEnvironmentHasNoData,
  selectEnvironmentReadiness,
  selectEnvironmentSettings,
  selectHasNoEnvironments,
  selectIsUserLoggedIn,
  selectSelectedEnvironmentId,
  selectSelectedLanguage,
  selectShouldShowHeadlineContainer,
  selectShowNavigationMenu,
} from 'core/+state/core.selectors';
import { AuthService } from 'core/auth/auth.service';
import { EnvironmentService } from 'core/environment/environment.service';
import { FrontendConfigService } from 'core/frontend-config.service';
import { TrackingWrapperService } from 'core/tracking/tracking-wrapper.service';
import {
  combineLatest,
  distinctUntilChanged,
  filter,
  first,
  mergeMap,
  Observable,
  takeUntil,
} from 'rxjs';
import { DestroyAbstractService } from 'shared/helper/destroy-abstract.service';
import { RootState } from './+state/app.reducer';
import { NoEnvironmentAvailableOverlayService } from 'core/environment/no-environment-available-overlay/no-environment-available-overlay.service';
import { LanguageService } from 'core/language/language.service';
import { DOCUMENT } from '@angular/common';
import { UserSettingsService } from 'core/user-settings/user-settings.service';
import { Languages } from 'core/language/language.interface';
import { EnvironmentSettingsService } from 'core/environment-settings/environment-settings.service';
import { GlobalQueryParamsService } from 'core/global-query-params.service';
import { CustomIconsService } from 'core/custom-icons.service';
import { EmptyEnvironmentNotificationService } from 'shared/ui/empty-environment-notification/empty-environment-notification.service';

@Component({
  selector: 'ista-daytona-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [NoEnvironmentAvailableOverlayService],
})
export class AppComponent extends DestroyAbstractService implements OnInit {
  selectShowNavigationMenu$: Observable<boolean> = this.ngrxStore.select(selectShowNavigationMenu);
  selectShowFooterWave$: Observable<boolean> = this.ngrxStore.select(selectShowFooterWave);

  selectShouldDisplaySmall$ = this.ngrxStore.select(selectShowSubNavigationMenu);

  selectShowEnvironmentContainer$ = this.ngrxStore.select(selectShowEnvironmentContainer);
  selectShowGlobalYearSelection$ = this.ngrxStore.select(selectShowGlobalYearSelection);
  selectShouldShowHeadlineContainer$ = this.ngrxStore.select(selectShouldShowHeadlineContainer);

  selectSelectedLanguage$ = this.ngrxStore.select(selectSelectedLanguage);
  selectHideSelectedPeriodInfo$ = this.ngrxStore.select(selectHideSelectedPeriodInfo);

  public environmentReady$: Observable<boolean> = this.ngrxStore.select(selectEnvironmentReadiness);

  constructor(
    private readonly _translateService: TranslateService,
    private readonly _frontendConfigService: FrontendConfigService,
    private readonly _router: Router,
    private readonly _environmentService: EnvironmentService,
    private readonly _authService: AuthService,
    private readonly _trackingService: TrackingWrapperService,
    private readonly ngrxStore: Store<RootState>,
    private noEnvironmentAvailableOverlayService: NoEnvironmentAvailableOverlayService,
    private languageService: LanguageService,
    private userSettingsService: UserSettingsService,
    private environmentSettingsService: EnvironmentSettingsService,
    private emptyEnvironmentNotificationService: EmptyEnvironmentNotificationService,
    private globalQueryParamsService: GlobalQueryParamsService,
    private customIconsService: CustomIconsService,
    @Inject(LOCALE_ID) public locale: string,
    @Inject(DOCUMENT) private document: Document
  ) {
    super();
    this._router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this._trackingService.addGTM({ eventCategory: 'page_load' }, [event.url]);
      }
    });

    this.initialSetup();
  }

  public get isLoggedIn$(): Observable<boolean> {
    return this.ngrxStore.select(selectIsUserLoggedIn);
  }

  ngOnInit(): void {
    this.showNoEnvironmentOverlayWhenHasNoEnvironments();
    this.showEnvironmentHasNoDataOverlay();
    this.listenToLangChangeAndUpdateAppLang();
    this.listenToEnvironmentChangeAndLoadSettingsAndPeriods();
    this.loadUserSettings();
    this.globalQueryParamsService.listenToRouteChanges().subscribe();
    this.globalQueryParamsService.updateQueryParamsInUrl().subscribe();
    this.customIconsService.registerCustomIcons();
  }

  loadUserSettings(): void {
    this.ngrxStore
      .select(selectEnvironmentReadiness)
      .pipe(
        filter(Boolean),
        first(),
        mergeMap(() => this.userSettingsService.getUserSettingsAndUpdateStore())
      )
      .subscribe();
  }

  listenToEnvironmentChangeAndLoadSettingsAndPeriods(): void {
    this.ngrxStore
      .select(selectSelectedEnvironmentId)
      .pipe(
        filter(Boolean),
        distinctUntilChanged(),
        mergeMap((envId) =>
          this.environmentSettingsService.getEnvironmentSettingsAndUpdateStore(envId)
        ),
        takeUntil(this._onDestroy$)
      )
      .subscribe();

    this.ngrxStore
      .select(selectEnvironmentSettings)
      .pipe(
        filter(Boolean),
        mergeMap(() => this._environmentService.loadReportingPeriods()),
        takeUntil(this._onDestroy$)
      )
      .subscribe();
  }

  listenToLangChangeAndUpdateAppLang(): void {
    this.selectSelectedLanguage$
      .pipe(
        filter((lang) => !!lang),
        distinctUntilChanged(),
        takeUntil(this._onDestroy$)
      )
      .subscribe((lang) => {
        this.languageService.saveLanguage(lang as Languages);
        if (!!this.document?.documentElement) {
          this.document.documentElement.lang = lang as Languages;
        }
      });
  }

  showEnvironmentHasNoDataOverlay(): void {
    combineLatest([
      this.ngrxStore.select(selectEnvironmentHasNoData),
      this.ngrxStore.select(selectEnvironmentReadiness),
    ])
      .pipe(filter(([hasNoData, envIsReady]) => hasNoData && envIsReady))
      .subscribe(() => {
        this.emptyEnvironmentNotificationService.open();
      });
  }

  showNoEnvironmentOverlayWhenHasNoEnvironments(): void {
    this.ngrxStore
      .select(selectHasNoEnvironments)
      .pipe(filter(Boolean))
      .subscribe(() => {
        this.noEnvironmentAvailableOverlayService.open();
      });
  }

  private async initialSetup(): Promise<void> {
    // 1. Load frontend config values from backend
    await this._frontendConfigService.loadFrontendConfigValues();
    // 2. Handle login
    const loggedIn = await this._authService.handleLogin();
    // 3. Load environment basics
    if (loggedIn) this._environmentService.loadEnvironments();
  }
}
