/* eslint-disable @typescript-eslint/member-ordering */
import { Component, EventEmitter, Input, Output } from '@angular/core';

import { TrackingWrapperService } from 'core/tracking/tracking-wrapper.service';
import { UtilHelper } from 'shared/helper/util-helper';
import { FilterSubTab, FilterTab, FilterTabItem } from '../filter-tab.interface';

@Component({
  selector: 'ista-daytona-filter-tab-small',
  templateUrl: './filter-tab-small.component.html',
  styleUrls: ['./filter-tab-small.component.scss'],
})
export class FilterTabSmallComponent {
  get config(): FilterSubTab {
    return this._config;
  }
  @Input() set config(value: FilterSubTab) {
    if (!!value) {
      this._config = [...value];
      this.setActiveTabs(this._config);
    }
  }
  @Output() clickedFilter = new EventEmitter<FilterTab>();
  public activeMainTab: FilterTabItem | undefined;
  private _config!: FilterSubTab;

  constructor(private readonly trackingService: TrackingWrapperService) {}

  public setActiveMainMenu(item: FilterTabItem): void {
    this.activeMainTab = item;
    this.clickedFilter.emit({
      left: [item],
      selectedYear: UtilHelper.getSelectedYearDummy(),
    });
    this.trackingService.addGTM(
      {
        eventCategory: 'elementClick',
        eventLabel: item.value || item.label,
        eventAction: 'tabNavigationClick',
      },
      [item.value || item.label]
    );
  }

  private setActiveTabs(tabs: FilterTabItem[] | undefined): void {
    tabs?.forEach((item) => {
      if (!!item.active) {
        this.activeMainTab = item;
      }
    });

    if (tabs) {
      if (!this.activeMainTab) {
        this.activeMainTab = tabs[0];
      }
    }
  }
}
