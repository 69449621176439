export enum IconType {
  EYE_OPEN = 'visibility',
  EYE_CLOSE = 'visibility_off',
  CLOSE = 'close',
  SEARCH = 'search',
  ARROW_RIGHT = 'chevron_right',
  ARROW_RIGHT_LONG = 'ease',
  DONE = 'done',
  CHECK = 'check',
  WARNING = 'warning',
}
