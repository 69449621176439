<div class="bg-white p-8 rounded-md top-0 left-0 bottom-0 right-0">
  <div class="flex justify-end">
    <a (click)="close()" class="text-base text-primary flex items-center">
      <mat-icon class="icon-size-6 text-primary">close</mat-icon>
    </a>
  </div>
  <div class="flex justify-center items-center flex-col my-20 mx-24">
    <div class="mb-8">
      <mat-icon class="text-warn icon-size-16">person_search</mat-icon>
    </div>
    <h3 class="text-warn">{{'no-environment-overlay.headline' | translate}}</h3>
    <p class="text-center h4 my-8 font-normal" [innerHTML]="'empty-environment-overlay.subheadline' | translate | safe"></p>
    <p
      [innerHTML]="'empty-environment-overlay.options' | translate | safe"
      class="text-center h4 mb-8 font-normal"
    ></p>
    <p class="mb-8 h4 font-bold" [innerHTML]="'no-environment-overlay.info' | translate | safe"></p>
    <div class="flex justify-center items-center">
      <a (click)="copyEmailIntoClipBoard()" class="mr-12">{{ 'no-environment-overlay.esg-email' | translate }}</a>
      <ista-daytona-button
        data-cy="empty-environment-notification-button"
        (click)="close()"
        [label]="'empty-environment-overlay.upload' | translate"
      ></ista-daytona-button>
    </div>
  </div>
</div>
