/* eslint-disable @typescript-eslint/member-ordering */

import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import { FormControl, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { map, Observable, startWith } from 'rxjs';

import { TranslateModule } from '@ngx-translate/core';
import { SelectItem } from 'shared/ui/basic/select/select.interface';
import { MatIconModule } from '@angular/material/icon';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { Environment } from '../environment.interface';
import { EnvironmentDropdownPipe } from 'shared/pipes/environment-dropdown.pipe';

@Component({
  selector: 'ista-daytona-environment-select',
  templateUrl: './environment-select.component.html',
  styleUrls: ['./environment-select.component.scss'],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatAutocompleteModule,
    TranslateModule,
    MatButtonModule,
    EnvironmentDropdownPipe,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      // eslint-disable-next-line no-use-before-define
      useExisting: forwardRef(() => EnvironmentSelectComponent),
      multi: true,
    },
  ],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EnvironmentSelectComponent {
  @Input() envs: Environment[] = [];
  @Input() title = '';
  @Input() disabled = false;
  @Output() selectedEnvChanged = new EventEmitter<Environment>();

  // mat-autocomplete writes user-selected values
  // automatically in here, so the type is string | SelectItem
  public singleControl = new FormControl<string | SelectItem>('');
  public lastSelectedEnv: Environment | undefined;
  public filteredSingleOptions$: Observable<Environment[]> = this.singleControl.valueChanges.pipe(
    startWith(''),
    map((value) => this._filterSingleOptions(value ?? ''))
  );

  @HostListener('document:click', ['$event'])
  clickOutsideEnvSelect(event: Event): void {
    if (
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      !this.element.nativeElement.contains(event.target) &&
      this.singleControl.value !== this.lastSelectedEnv
    ) {
      this.selectedEnv = this.lastSelectedEnv;
    }
  }

  constructor(private element: ElementRef) {}

  @Input()
  get selectedEnv(): Environment | undefined | null {
    return this._selectedEnv;
  }

  set selectedEnv(selectedEnv: Environment | undefined | null) {
    this._selectedEnv = selectedEnv;
    if (selectedEnv) {
      this.lastSelectedEnv = selectedEnv;
      // For outside changes, like initial env loaded
      this.singleControl.setValue(selectedEnv, { emitEvent: false });
    }
  }

  private _selectedEnv: Environment | undefined | null = null;

  public onOptionSelected(item: SelectItem): void {
    const env = this.envs.find((env) => env.id === item.id);
    if (env) {
      this.selectedEnv = env;
      this.selectedEnvChanged.emit(env);
    }
    if (env !== this.lastSelectedEnv) {
      this.lastSelectedEnv = env;
    }
  }

  clearInput(): void {
    this.singleControl.reset();
  }

  displayFn(item: SelectItem): string {
    return item ? item.name : '';
  }

  private _filterSingleOptions(input: string | SelectItem): Environment[] {
    // If one option is selected, that is, the input is of type SelectItem,
    // we want to show every option to the user again
    if (typeof input === 'string') {
      return this.envs.filter(
        (env) =>
          env.name.toLowerCase().includes(input.toLowerCase()) ||
          env.customerNumbers.filter((customerNo) => customerNo.includes(input.toLowerCase()))
            .length
      );
    } else {
      return this.envs;
    }
  }
}
