import { Directive, HostListener, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { RootState } from 'app/+state/app.reducer';
import { filter, first, forkJoin } from 'rxjs';
import { selectGlobalSelectedYear, selectSelectedEnvironmentId } from 'core/+state/core.selectors';
import { Router } from '@angular/router';

@Directive({ selector: '[linkQueryParams]', standalone: true })
export class LinkWithGlobalQueryParamsDirective {
  @Input() link!: string;
  @Input() customParams: Record<string, any> = {};
  @Input() overrideTarget?: '_self' | '_blank';

  constructor(private ngrxStore: Store<RootState>, private router: Router) {}

  @HostListener('mousedown', ['$event'])
  openInNewTab(event: MouseEvent): void {
    forkJoin([
      this.ngrxStore.select(selectSelectedEnvironmentId).pipe(first()),
      this.ngrxStore.select(selectGlobalSelectedYear).pipe(first()),
    ])
      .pipe(filter(([environmentId]) => !!environmentId && !!this.link && event.button !== 2))
      .subscribe(([environmentId, year]) => {
        if (environmentId !== undefined) {
          if (
            (event.button === 1 && this.overrideTarget !== '_blank') ||
            this.overrideTarget === '_blank'
          ) {
            const customParamsAsString = this.getCustomParamsAsString();
            // Allow year to be empty string because an empty environment does not contain a year with data
            window.open(
              `${this.link}?environmentId=${environmentId}&year=${year || ''}${
                customParamsAsString ? `&${customParamsAsString}` : ''
              }`,
              '_blank'
            );
          } else {
            this.router.navigate([this.link], {
              queryParams: {
                ...this.customParams,
              },
            });
          }
        }
      });
  }

  private getCustomParamsAsString(): string {
    return Object.entries(this.customParams)
      .map(([key, value]) => `${key}=${value as string}`)
      .join('&');
  }
}
