<mat-checkbox
  #checkbox
  (click)="change(checkbox.checked)"
  [attr.data-qa]="dataQa"
  [attr.color]="color"
  [checked]="isChecked"
  [color]="color"
  [disabled]="isDisabled"
  [indeterminate]="indeterminate"
>
  <span [innerHTML]="label | safe"></span>
</mat-checkbox>
