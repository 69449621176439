<div
  *ngIf="!!(isLoggedIn$ | async); else notLoggedIn"
  [class.small]="selectShouldDisplaySmall$ | async"
  class="root-container"
>
  <ista-daytona-navigation
    *ngIf="selectShowNavigationMenu$ | async"
    [displaySmall]="selectShouldDisplaySmall$ | async"
  ></ista-daytona-navigation>
  <ng-container *ngIf="selectShouldDisplaySmall$ | async">
    <div class="max-h-dvh">
      <ista-daytona-portfolio-group-navigation/>
    </div>
  </ng-container>
  <div [class.mb-30]="selectShowFooterWave$ | async" class="content">
    <div *ngIf="selectShouldShowHeadlineContainer$ | async" class="content--header">
      <ista-daytona-global-sticky-header
        [showSubNavigationLayout]="selectShouldDisplaySmall$ | async | nullToUndefined"
      >
        <ista-daytona-environment-container
          *ngIf="selectShowEnvironmentContainer$ | async"
          class="mr-2"
        ></ista-daytona-environment-container>
        @if (!(selectHideSelectedPeriodInfo$ | async)) {
          <ista-daytona-selected-period-info class="mr-6"></ista-daytona-selected-period-info>
        }
        <ista-daytona-global-year-selection
          *ngIf="selectShowGlobalYearSelection$ | async"
        ></ista-daytona-global-year-selection>
      </ista-daytona-global-sticky-header>
    </div>

    <div class="content--inner">
      <router-outlet
        *ngIf="environmentReady$ | async; else isLoadingScreen"
      ></router-outlet>
      <ng-template #isLoadingScreen>
        <ista-daytona-loader
          [diameter]="'64'"
          [label]="'environment.loadEnvironmentData' | translate"
        ></ista-daytona-loader>
      </ng-template>
    </div>
    <ista-daytona-footer
      [displayWave]="selectShowFooterWave$ | async | nullToUndefined"
    ></ista-daytona-footer>
  </div>
</div>
<ista-daytona-notification></ista-daytona-notification>

<ng-template #notLoggedIn>
  <ista-daytona-loader [diameter]="'64'" [label]="'login.tryToLogin' | translate"></ista-daytona-loader>
</ng-template>
