import { Injectable } from '@angular/core';
import { GroupsTreeNavigationStore } from 'core/groups-tree-navigation/groups-tree-navigation.store';
import { Group, SiteOfGroup } from 'core/groups/groups.interface';
import {
  combineLatest,
  debounceTime,
  delay,
  distinctUntilChanged,
  filter,
  first,
  forkJoin,
  map,
  mergeMap,
  NEVER,
  Observable,
  of,
  skip,
  tap,
} from 'rxjs';
import {
  selectGroupIdParam,
  selectIsIdParamIdGroup,
  selectIsIdParamIdSite,
  selectIsPortfolioUrl,
} from 'app/+state/router/reduced-route.selectors';
import { GroupHelper } from 'core/groups-tree-navigation/utils/group-helper';
import { Store } from '@ngrx/store';
import { RootState } from 'app/+state/app.reducer';
import {
  selectGroups,
  selectSelectedEnvironmentId,
  selectSelectedGroupOrSite,
  selectTreeNavigationSortedGroups,
  selectUnassignedSitesGroup,
} from 'core/+state/core.selectors';
import {
  addNewGroupToGroups,
  deleteGroupFromGroup,
  deleteSiteFromGroup,
  moveAndAddGroup,
  moveAndAddGroupToRoot,
  moveAndAddSite,
  storeSelectedGroupOrSite,
  updateSubNavigationSelectedHeadline,
  updateUnassignedSites,
} from 'core/+state/core.actions';
import { GroupService } from 'core/groups/group.service';
import { GroupsTreeNavigationHttpService } from 'core/groups-tree-navigation/groups-tree-navigation-http.service';
import { Router } from '@angular/router';
import { GroupMapper } from 'core/groups/group.mapper';
import { UNASSIGNED_SITES_GROUP_ID } from 'core/constants';
import { MatDialog } from '@angular/material/dialog';
import { DeleteConfirmDialogComponent } from 'core/groups-tree-navigation/delete-confirm-dialog/delete-confirm-dialog.component';
import { catchError } from 'rxjs/operators';
import { DeleteRevertDialogComponent } from 'core/groups-tree-navigation/delete-revert-dialog/delete-revert-dialog.component';
import { ActiveNodeType } from 'core/groups-tree-navigation/interfaces';
import { updatePossibleEditingPortfolioNavigationFinishedState } from 'app/features/portfolio/+state/portfolio.actions';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { StickyHeaderService } from 'core/sticky-header/sticky-header.service';
import { Title } from '@angular/platform-browser';
import { BASE_PAGE_TITLE } from '../title-strategy.service';

@Injectable()
export class GroupsTreeNavigationService {
  constructor(
    private readonly ngrxStore: Store<RootState>,
    private groupsTreeNavigationStore: GroupsTreeNavigationStore,
    private readonly groupService: GroupService,
    private groupsTreeNavigationHttpService: GroupsTreeNavigationHttpService,
    private readonly route: Router,
    private dialog: MatDialog,
    private toastService: ToastrService,
    private translateService: TranslateService,
    private stickyHeaderService: StickyHeaderService,
    private readonly _titleService: Title
  ) {}

  showDeleteConfirmDialog(itemName: string, top: number, left: number): Observable<boolean> {
    const dialog = this.dialog.open(DeleteConfirmDialogComponent, {
      data: {
        itemName,
      },
      hasBackdrop: false,
      position: {
        top: `${top}px`,
        left: `${left}px`,
      },
    });
    return dialog.afterClosed() as Observable<boolean>;
  }

  showDeleteRevertDialog(itemName: string, top: number, left: number): Observable<boolean> {
    const dialog = this.dialog.open(DeleteRevertDialogComponent, {
      data: {
        itemName,
      },
      hasBackdrop: false,
      position: {
        top: `${top}px`,
        left: `${left}px`,
      },
    });
    dialog
      .afterOpened()
      .pipe(delay(5000))
      .subscribe(() => dialog.close(false));
    return dialog.afterClosed() as Observable<boolean>;
  }

  navigateToSite(site: SiteOfGroup): void {
    this.updateSelectedGroupOrSite(site.id);
    this.updateActiveNode(site, 'site');
    this.updateHeadline(site);
    this.updatePageTitle(site);
    this.route.navigate(['portfolio', 'site', site.id]);
  }

  navigateToGroup(group: Group): void {
    this.updateSelectedGroupOrSite(group.id);
    this.updateActiveNode(group, 'group');
    this.updateHeadline(group);
    this.updatePageTitle(group);
    this.route.navigate(['portfolio', 'group', group.id]);
  }

  navigateToPortfolio(): void {
    this.route.navigate(['portfolio']);
  }

  listenToPortfolioUrlChanges(): Observable<any> {
    return this.ngrxStore.select(selectIsPortfolioUrl).pipe(
      distinctUntilChanged(),
      filter(Boolean),
      tap(() => {
        this.updateSelectedGroupOrSite();
        this.groupsTreeNavigationStore.resetStore();
      })
    );
  }

  listenToEnvironmentChangesAndNavigateToPortfolio(): Observable<unknown> {
    return this.ngrxStore.select(selectSelectedEnvironmentId).pipe(
      filter(Boolean),
      skip(1), // skip initial Value
      distinctUntilChanged(),
      tap(() => this.navigateToPortfolio())
    );
  }

  listenToGroupChanges(): Observable<Group[]> {
    return combineLatest([
      this.ngrxStore.select(selectGroupIdParam),
      this.ngrxStore.select(selectIsIdParamIdGroup),
      this.ngrxStore.select(selectTreeNavigationSortedGroups),
      this.ngrxStore.select(selectUnassignedSitesGroup),
      this.ngrxStore.select(selectGroups),
    ]).pipe(
      debounceTime(200),
      tap(([idParam, isGroupId, treeNavigationGroups, , groups]) => {
        if (idParam && isGroupId && groups && treeNavigationGroups) {
          const isGroupIdExists = GroupMapper.flatAllGroups(treeNavigationGroups).find(
            (group) => group.id === idParam
          );
          if (!isGroupIdExists) {
            this.navigateToPortfolio();
          }
        }
      }),
      tap(([idParam, isGroupId, groups]) => {
        if (idParam && isGroupId) {
          const hierarchy = groups ? GroupHelper.getGroupHierarchy(groups, idParam) : [];
          if (hierarchy.length) {
            const targetNode = hierarchy.pop() as Group;
            this.groupsTreeNavigationStore.addNodesId(hierarchy.map((g) => g.id));
            this.updateHeadline(targetNode);
            this.updatePageTitle(targetNode);
          }
          this.updateActiveNode(
            {
              id: idParam,
            },
            'group'
          );
        }
      }),
      map(([, , groups, unassignedGroup]) => [...(groups ?? []), unassignedGroup]),
      tap((groups) => this.groupsTreeNavigationStore.updateGroupsAndExpandedNodeIds(groups)),
      catchError((err) => {
        console.error(err);
        this.showErrorAndReloadPageIsRequired();
        return [];
      })
    );
  }

  // Http Functions Start
  updateSiteGroup(siteId: number, groupId: number): Observable<unknown> {
    return this.ngrxStore.select(selectSelectedEnvironmentId).pipe(
      first(),
      filter(Boolean),
      mergeMap((envId) =>
        this.groupsTreeNavigationHttpService.updateSiteGroup(envId, siteId, groupId)
      ),
      catchError((err) => {
        console.error(err);
        this.showErrorAndReloadPageIsRequired();
        return NEVER;
      })
    );
  }

  updateGroupParentGroup(groupId: number, parentId: number): Observable<unknown> {
    return this.ngrxStore.select(selectSelectedEnvironmentId).pipe(
      first(),
      filter(Boolean),
      mergeMap((envId) =>
        this.groupsTreeNavigationHttpService.updateGroupParentId(envId, groupId, parentId)
      ),
      catchError((err) => {
        console.error(err);
        this.showErrorAndReloadPageIsRequired();
        return NEVER;
      })
    );
  }

  getUnassignedSites(): Observable<SiteOfGroup[]> {
    return this.ngrxStore.select(selectSelectedEnvironmentId).pipe(
      first(),
      filter(Boolean),
      mergeMap((envId) =>
        forkJoin([of(envId), this.groupsTreeNavigationHttpService.getUnassignedSites(envId)])
      ),
      mergeMap(([envId, sites]) =>
        forkJoin([
          of(sites),
          this.ngrxStore.select(selectSelectedEnvironmentId).pipe(
            first(),
            map((currentEnvId) => currentEnvId === envId)
          ),
        ])
      ),
      filter(([, isEnvIdMatched]) => !!isEnvIdMatched),
      map(([sites]) => sites),
      tap((unassignedSites) =>
        this.ngrxStore.dispatch(
          updateUnassignedSites({
            unassignedSites,
          })
        )
      ),
      catchError((err) => {
        console.error(err);
        this.showErrorAndReloadPageIsRequired();
        return of([]);
      })
    );
  }

  loadSites(group: Group): void {
    this.groupService.loadSitesOfGroup(group.id);
  }

  createGroup(name: string, parentId?: number): Observable<number> {
    return this.ngrxStore.select(selectSelectedEnvironmentId).pipe(
      first(),
      filter(Boolean),
      mergeMap((envId) => this.groupsTreeNavigationHttpService.createGroup(envId, name, parentId)),
      tap((newGroupId) =>
        this.ngrxStore.dispatch(
          addNewGroupToGroups({
            newGroup: {
              id: newGroupId,
              groups: [],
              name,
            },
            parentGroupId: parentId ?? 0,
          })
        )
      ),
      catchError((err) => {
        console.error(err);
        this.showErrorAndReloadPageIsRequired();
        return of(-1);
      })
    );
  }

  deleteGroup(group: Group, top: number, left: number): Observable<unknown> {
    return this.showDeleteConfirmDialog(group.name, top, left).pipe(
      first(Boolean),
      mergeMap(() =>
        this.showDeleteRevertDialog(group.name, top, left).pipe(
          map((shouldRevert) => !shouldRevert)
        )
      ),
      filter(Boolean),
      mergeMap(() => this.ngrxStore.select(selectSelectedEnvironmentId).pipe(first())),
      filter(Boolean),
      tap(() => {
        this.ngrxStore.dispatch(
          deleteGroupFromGroup({
            groupId: group.id,
          })
        );
      }),
      mergeMap((envId) =>
        this.groupsTreeNavigationHttpService.deleteGroup(envId, group.id).pipe(
          catchError((err) => {
            console.error('deleteGroup', err);
            this.showErrorAndReloadPageIsRequired();
            return NEVER;
          })
        )
      ),
      mergeMap(() => this.getUnassignedSites()),
      mergeMap(() => this.ngrxStore.select(selectSelectedGroupOrSite).pipe(first())),
      filter((selectedSiteOrGroup) => selectedSiteOrGroup?.id === group.id),
      tap(() => this.stickyHeaderService.resetHeader()),
      tap(() => this.navigateToPortfolio()),
      catchError((err) => {
        console.error('deleteGroup', err);
        return NEVER;
      })
    );
  }

  deleteSite(
    top: number,
    left: number,
    site: SiteOfGroup,
    parentGroup: Group
  ): Observable<unknown> {
    return this.showDeleteConfirmDialog(site.name, top, left).pipe(
      first(Boolean),
      mergeMap(() =>
        this.showDeleteRevertDialog(site.name, top, left).pipe(map((shouldRevert) => !shouldRevert))
      ),
      filter(Boolean),
      mergeMap(() => this.ngrxStore.select(selectSelectedEnvironmentId)),
      filter(Boolean),
      tap(() => {
        this.ngrxStore.dispatch(
          deleteSiteFromGroup({
            parentGroup,
            site,
          })
        );
      }),
      mergeMap((envId) =>
        this.groupsTreeNavigationHttpService.deleteSite(envId, site.id).pipe(
          catchError((err) => {
            console.error('deleteSite', err);
            this.showErrorAndReloadPageIsRequired();
            return NEVER;
          })
        )
      ),
      mergeMap(() => this.ngrxStore.select(selectSelectedGroupOrSite).pipe(first())),
      filter((selectedSiteOrGroup) => selectedSiteOrGroup?.id === site.id),
      tap(() => this.stickyHeaderService.resetHeader()),
      tap(() => this.navigateToPortfolio()),
      catchError((err) => {
        console.error('deleteSite', err);
        return NEVER;
      })
    );
  }

  getSiteHierarchyAndSetHierarchyGroups(
    envId: number,
    siteId: number
  ): Observable<Group | undefined> {
    return this.groupsTreeNavigationHttpService.getSiteHierarchy(envId, siteId).pipe(
      tap((hierarchy) => {
        this.handleSiteHierarchy(siteId, hierarchy);
      })
    );
  }

  // Http Functions End

  // global Store Functions Start
  setGroupOrSiteId(): Observable<unknown> {
    return this.ngrxStore
      .select(selectGroupIdParam)
      .pipe(tap((id) => this.updateSelectedGroupOrSite(id)));
  }

  updateSelectedGroupOrSite(id?: number): void {
    this.ngrxStore.dispatch(
      storeSelectedGroupOrSite({
        selectedGroupOrSite: id
          ? {
              groups: [],
              name: '',
              id,
              sites: [],
            }
          : undefined,
      })
    );
  }

  checkIfISiteAndGetSiteHierarchyAndSetHierarchyGroups(): Observable<unknown> {
    return combineLatest([
      this.ngrxStore.select(selectSelectedEnvironmentId),
      this.ngrxStore.select(selectGroupIdParam),
      this.ngrxStore.select(selectIsIdParamIdSite),
      this.ngrxStore.select(selectGroups),
    ]).pipe(
      filter(([envId, , , selectGroups]) => !!envId && !!selectGroups),
      first(),
      filter(([, idParam, isSiteUrl]) => !!idParam && isSiteUrl),
      mergeMap(([envId, idParam]) =>
        forkJoin([
          this.getSiteHierarchyAndSetHierarchyGroups(envId as number, idParam as number),
          this.getSiteAndUpdateHeadline(envId as number, idParam as number),
        ])
      ),
      catchError((err) => {
        console.error(err);
        this.navigateToPortfolio();
        return NEVER;
      })
    );
  }

  updateHeadline({ name }: { name: string }): void {
    this.ngrxStore.dispatch(
      updateSubNavigationSelectedHeadline({
        subNavigationSelectedHeadline: name,
      })
    );
  }

  updatePageTitle({ name }: { name: string }): void {
    this._titleService.setTitle(`${name} - ${BASE_PAGE_TITLE}`);
  }

  getSiteAndUpdateHeadline(envId: number, siteId: number): Observable<unknown> {
    return this.groupsTreeNavigationHttpService.getSite(envId, siteId).pipe(
      tap((site) => {
        this.updateHeadline(site);
        this.updatePageTitle(site);
      })
    );
  }

  moveAndAddSite(site: SiteOfGroup, targetGroup: Group): void {
    this.ngrxStore.dispatch(
      moveAndAddSite({
        site,
        targetGroup,
      })
    );
    this.updateSiteGroup(site.id, targetGroup.id).subscribe();
  }

  moveAndAddGroup(group: Group, targetGroup: Group): void {
    if (targetGroup.id === UNASSIGNED_SITES_GROUP_ID) {
      this.ngrxStore.dispatch(
        moveAndAddGroupToRoot({
          group,
          targetGroup,
        })
      );
    } else {
      this.ngrxStore.dispatch(
        moveAndAddGroup({
          group,
          targetGroup,
        })
      );
    }
    this.updateGroupParentGroup(group.id, targetGroup.id).subscribe();
  }

  // global Store Functions End

  // local Store Functions Start
  isExpanded(group: Group): boolean {
    return this.groupsTreeNavigationStore.getIsNodeIdExist(group.id);
  }

  getIsNodeActive(nodeId: number, type: ActiveNodeType): boolean {
    return this.groupsTreeNavigationStore.getIsNodeActive(nodeId, type);
  }

  updateActiveNode({ id }: { id: number }, type: ActiveNodeType): void {
    this.groupsTreeNavigationStore.updateActiveNode({
      nodeId: id,
      type,
    });
  }

  updateOpenAllGroups(openAllGroups: boolean): void {
    this.groupsTreeNavigationStore.updateOpenAllGroups(openAllGroups);
  }

  toggleNode(node: Group): void {
    if (this.groupsTreeNavigationStore.getIsNodeIdExist(node.id)) {
      this.groupsTreeNavigationStore.removeExpandedNodeIds([
        node.id,
        ...GroupHelper.flattenGroups(node.groups).map((g) => g.id),
      ]);
    } else {
      this.groupsTreeNavigationStore.addNodesId([node.id]);
    }
  }

  // local Store Functions End

  // Drag and Drop Start
  dragItemStart(
    event: DragEvent,
    item: Group | SiteOfGroup,
    type: 'site' | 'group',
    parentGroupId?: number
  ): void {
    if (event.dataTransfer) {
      event.dataTransfer.setData(type, '');
      event.dataTransfer.dropEffect = 'move';
      this.groupsTreeNavigationStore.updateDraggedItem({
        ...item,
        parentGroupId,
      });
    }
  }

  handleDragOver($event: DragEvent): void {
    if (
      $event?.dataTransfer?.types?.includes('site') ||
      $event?.dataTransfer?.types?.includes('group')
    ) {
      $event.preventDefault();
    }
  }

  handleDrop(event: DragEvent, node: Group): void {
    const isGroup = event?.dataTransfer?.types?.includes('group');
    const isSite = event?.dataTransfer?.types?.includes('site');
    const draggedItem = this.groupsTreeNavigationStore.getDraggedItem();
    event.preventDefault();
    if (draggedItem) {
      if (isGroup) {
        this.handleGroupDrop(draggedItem as Group, node);
      } else if (isSite) {
        this.handleSiteDrop(draggedItem as SiteOfGroup, node);
      }
    }
    this.groupsTreeNavigationStore.resetDraggedItem();
  }

  // Drag and Drop End

  private handleGroupDrop(draggedItem: Group, node: Group): void {
    if (GroupHelper.isAllowToMoveGroup(draggedItem, node)) {
      this.checkIfDropGroupRequireReloadAndSetReload(draggedItem, node).subscribe();
      this.moveAndAddGroup(draggedItem, node);
    } else {
      this.showDragAndDropNotAllowedToast('group-tree-navigation.drop-is-not-allowed');
    }
  }

  private handleSiteDrop(draggedItem: SiteOfGroup, node: Group): void {
    if (GroupHelper.isAllowToMoveSite(draggedItem, node)) {
      this.checkIfDropSiteRequireReloadAndSetReload(draggedItem, node).subscribe();
      this.moveAndAddSite(draggedItem, node);
    } else {
      this.showDragAndDropNotAllowedToast('group-tree-navigation.site-drop-is-not-allowed');
    }
  }

  private checkIfDropRequireReload(
    { id }: { id: number },
    node: Group,
    type: 'site' | 'group'
  ): Observable<boolean> {
    return of(
      this.groupsTreeNavigationStore.getActiveNodeType() === 'group' &&
        this.groupsTreeNavigationStore.getNodeActiveId() === node.id
    ).pipe(
      filter(Boolean),
      mergeMap(() =>
        this.ngrxStore.select(selectTreeNavigationSortedGroups).pipe(filter(Boolean), first())
      ),
      map((groups) => {
        const siteHierarchy = GroupHelper.flattenGroups(
          type === 'site'
            ? GroupHelper.getSiteHierarchy(groups, id)
            : GroupHelper.getGroupHierarchy(groups, id)
        );
        const targetGroupChildren = GroupHelper.flattenGroups(
          GroupHelper.getGroupHierarchy(
            groups,
            this.groupsTreeNavigationStore.getNodeActiveId() as number
          )
        );
        return [...siteHierarchy, ...targetGroupChildren].map((g) => g.id).includes(node.id);
      })
    );
  }

  private checkIfDropGroupRequireReloadAndSetReload(
    draggedItem: Group,
    node: Group
  ): Observable<boolean> {
    return this.checkIfDropRequireReload(draggedItem, node, 'group').pipe(
      filter(Boolean),
      tap(() => {
        this.ngrxStore.dispatch(
          updatePossibleEditingPortfolioNavigationFinishedState({
            possibleState: 1,
          })
        );
      })
    );
  }

  private checkIfDropSiteRequireReloadAndSetReload(
    draggedItem: SiteOfGroup,
    node: Group
  ): Observable<boolean> {
    return this.checkIfDropRequireReload(draggedItem, node, 'site').pipe(
      filter(Boolean),
      tap(() => {
        this.ngrxStore.dispatch(
          updatePossibleEditingPortfolioNavigationFinishedState({
            possibleState: 1,
          })
        );
      })
    );
  }

  private handleSiteHierarchy(siteId: number, hierarchy?: Group): void {
    if (hierarchy) {
      // Site has Parent Group
      const hierarchyGroups = GroupMapper.flatAllGroups([hierarchy]);
      this.groupsTreeNavigationStore.addNodesId(hierarchyGroups.map((g) => g.id));
      const directParentOfSite = hierarchyGroups.pop() as Group;
      // we need to test, how BE fast is, it with a Group with a lot of LGs.
      // if it's Slow we need to override loadSites to load the Sites in the BE, and in mean Time save the Single Site in Store
      this.loadSites(directParentOfSite);
    } else {
      // Site is in Root
      this.groupsTreeNavigationStore.addNodesId([UNASSIGNED_SITES_GROUP_ID]);
    }
    this.updateActiveNode(
      {
        id: siteId,
      },
      'site'
    );
  }

  private showErrorAndReloadPageIsRequired(): void {
    this.toastService.show(
      this.translateService.instant('group-tree-navigation.error-occurred') as string,
      '',
      {
        timeOut: 5000,
        tapToDismiss: true,
        payload: {
          showIcon: true,
          customFontIcon: 'info',
        },
      },
      'error'
    );
  }

  private showDragAndDropNotAllowedToast(messageKey: string): void {
    this.toastService.show(
      this.translateService.instant(messageKey) as string,
      '',
      {
        positionClass: 'toast-top-left',
        timeOut: 5000,
        tapToDismiss: true,
        payload: {
          showIcon: true,
          customFontIcon: 'info',
        },
      },
      'warning'
    );
  }
}
