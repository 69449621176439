import { Directive, Input } from '@angular/core';

import { UtilHelper } from '../../helper/util-helper';
import { EFileResponse, EFileResult } from './efile-response.interface';
import { ExcelFileResponse, RowResult } from './excelfile-response.interface';
import { FileUpload, FileUploadType, FileUploadValidStatus } from './file-upload.model';
import { FileUploadResponse } from './fileupload-response.interface';

@Directive()
export class FileUploadFunctionsService {
  @Input() fileUpload!: FileUpload;
  @Input() fileUploadResponse!: FileUploadResponse<ExcelFileResponse | EFileResponse>;
  public fileUploadValidStatus = FileUploadValidStatus;

  public get dataTag(): string {
    return this.fileUpload?.type === FileUploadType.EFILE ? 'E-Datensätze' : 'Daten';
  }

  public get getFileName(): string {
    return (this.fileUpload?.formData?.get('excelFile') as File)?.name ?? '';
  }

  public get getExcelFileSize(): string {
    const size = (this.fileUpload?.formData?.get('excelFile') as File)?.size;

    if (size !== undefined) {
      return `${UtilHelper.getRoundedValue(size / 1024) || 0} KB`;
    }

    return '?';
  }

  public get getFileDate(): number {
    return (this.fileUpload?.formData?.get('excelFile') as File)?.lastModified;
  }

  public get isExcelErrorFile(): boolean {
    return !(this.fileUploadResponse?.response as unknown as ExcelFileResponse)
      ?.importWasSuccessful;
  }

  public get isDataValid(): FileUploadValidStatus {
    // @todo: check for validation finish
    if (!this.fileUpload) {
      return this.fileUploadValidStatus.INVALID;
    }

    if (this.fileUpload?.type === FileUploadType.EXCEL) {
      return (this.fileUploadResponse?.response as unknown as ExcelFileResponse)
        ?.importWasSuccessful
        ? this.fileUploadValidStatus.VALID
        : this.fileUploadValidStatus.INVALID;
    }

    const efileResponse = this.fileUploadResponse?.response as unknown as EFileResponse;

    let index = 0;
    let foundItem = false;
    while (!foundItem && index < efileResponse.parsedAndValidatedFiles?.length) {
      foundItem = this.existsEFileErrors(efileResponse.parsedAndValidatedFiles[index]);
      index++;
    }

    return foundItem
      ? this.fileUploadValidStatus.INVALD_TO_UPLOAD
      : this.fileUploadValidStatus.VALID;
  }

  public get excelFileResponse(): ExcelFileResponse {
    return this.fileUploadResponse as unknown as ExcelFileResponse;
  }

  public get isEFileErrorFile(): boolean {
    return !(this.fileUploadResponse?.response as unknown as EFileResponse).parsedAndValidatedFiles;
  }

  public get eFileResponse(): EFileResponse {
    return this.fileUploadResponse as unknown as EFileResponse;
  }

  public get eFiles(): EFileResult[] {
    return (this.fileUploadResponse?.response as unknown as EFileResponse).parsedAndValidatedFiles;
  }

  public get excelRowResults(): RowResult[] {
    return (this.fileUploadResponse?.response as ExcelFileResponse)?.rowResults;
  }

  public getEFileSize(file: EFileResult): string {
    const files = this.fileUpload?.formData.getAll('eSatzFiles') as File[];
    const foundFile = files.find((item) => item.name === file.fileName);
    if (foundFile) {
      return `${UtilHelper.getRoundedValue(foundFile.size / 1024) || 0} KB`;
    }

    return '-';
  }

  public getEFileDate(file: EFileResult): number {
    const files = this.fileUpload?.formData.getAll('eSatzFiles') as File[];
    const foundFile = files.find((item) => item.name === file.fileName);
    if (foundFile) {
      return foundFile.lastModified;
    }

    return 0;
  }

  public existsEFileErrors(file: EFileResult): boolean {
    if (this.existsEFileGeneralError(file)) {
      return true;
    }

    return this.existsEFileBillingError(file);
  }

  public existsEFileBillingError(file: EFileResult): boolean {
    let index = 0;
    let foundItem = false;
    while (!foundItem && index < file.detectedBillings?.length) {
      foundItem = file.detectedBillings[index]?.validationHints?.length > 0;
      index++;
    }

    return foundItem;
  }

  public existsEFileGeneralError(file: EFileResult): boolean {
    return file.generalValidationHints?.length > 0;
  }

  public existsEFileBilling(file: EFileResult): boolean {
    return file.detectedBillings?.length > 0;
  }
}
