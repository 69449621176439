import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { DeleteObjectModule } from 'app/features/portfolio/delete-object/delete-object.module';
import { DeepLinkingSenderDirective } from 'core/deep-linking/deep-linking-sender.directive';
import { NgChartsModule } from 'ng2-charts';
import { PortfolioAnalysisChartService } from 'shared/ui/bar-chart-portfolio-analysis/portfolio-analysis-chart/portfolio-analysis-chart.service';
import { PortfolioAnalysisService } from 'shared/ui/bar-chart-portfolio-analysis/portfolio-analysis.service';
import { ButtonModule } from 'shared/ui/basic/button/button.module';
import { DropdownModule } from 'shared/ui/basic/dropdown/dropdown.module';
import { InputSearchModule } from 'shared/ui/basic/input-search/input-search.module';
import { InputModule } from 'shared/ui/basic/input/input.module';
import { LoaderModule } from 'shared/ui/basic/loader/loader.module';
import { TooltipModule } from 'shared/ui/basic/tooltip/tooltip.module';
import { ValueUnitModule } from 'shared/ui/basic/value-unit/value-unit.module';
import { SectionComponent } from 'shared/ui/section/section.component';
import { DirectivesModule } from '../directives/directives.module';
import { PipesModule } from './../pipes/pipes.module';
import { BarCardComponent } from './bar-card/bar-card.component';
import { BasicModule } from './basic/basic.module';
import { ChartModule } from './chart/chart.module';
import { Co2CostCardDistributionComponent } from './co2-cost-card-distribution/co2-cost-card-distribution.component';
import { Co2CostCardStageComponent } from './co2-cost-card-stage/co2-cost-card-stage.component';
import { Co2CostCardTotalComponent } from './co2-cost-card-total/co2-cost-card-total.component';
import { InfoCardComponent } from './info-card/info-card.component';
import { FileUploadModule } from './file-upload/file-upload.module';
import { FilterModule } from './filter/filter.module';
import { MonitoringCardComponent } from './monitoring-card/monitoring-card.component';
import { NavigationModule } from './navigation/navigation.module';
import { NotificationModule } from './notification/notification.module';
import { OSMComponent } from './osm/osm.component';
import { OverlayModule } from './overlay/overlay.module';
import { PageStepperModule } from './page-stepper/page-stepper.module';
import { PaginationModule } from './pagination/pagination.module';
import { PortfolioCardComponent } from './portfolio-card/portfolio-card.component';
import { TableSiteModule } from './table-site/table-site.module';
import { PeriodToggleComponent } from 'app/features/reporting/period-toggle/period-toggle.component';
import { ContentCardOpenComponent } from 'shared/ui/basic/content-card-open/content-card-open.component';
import { ValueUnitCardComponent } from 'shared/ui/value-unit-card/value-unit-card.component';
import { IconLabelComponent } from 'shared/ui/icon-label/icon-label.component';
import { CardHeadlineComponent } from 'shared/ui/card-headline/card-headline.component';

const imExportComponents = [
  MonitoringCardComponent,
  PortfolioCardComponent,
  OSMComponent,
  SectionComponent,
  Co2CostCardTotalComponent,
  Co2CostCardStageComponent,
  Co2CostCardDistributionComponent,
];

const imExportModule = [
  BasicModule,
  NavigationModule,
  FilterModule,
  NotificationModule,
  ChartModule,
  TableSiteModule,
  FileUploadModule,
  OverlayModule,
  DeleteObjectModule,
  ValueUnitModule,
  TooltipModule,
  ButtonModule,
  InputModule,
  LoaderModule,
  DropdownModule,
  InputSearchModule,
  PageStepperModule,
  PaginationModule,
  BasicModule,
  NavigationModule,
  FilterModule,
  NotificationModule,
  ChartModule,
  TableSiteModule,
  FileUploadModule,
  OverlayModule,
  DeleteObjectModule,
  ValueUnitModule,
  TooltipModule,
  ButtonModule,
  InputModule,
  LoaderModule,
  DropdownModule,
  InputSearchModule,
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    DirectivesModule,
    NgChartsModule,
    TranslateModule,
    PipesModule,
    ...imExportModule,
    DeepLinkingSenderDirective,
    ValueUnitModule,
    TooltipModule,
    ButtonModule,
    InputModule,
    MatIconModule,
    NgOptimizedImage,
    MatButtonToggleModule,
    MatFormFieldModule,
    MatOptionModule,
    MatSelectModule,
    PeriodToggleComponent,
    ContentCardOpenComponent,
    ValueUnitCardComponent,
    IconLabelComponent,
    CardHeadlineComponent,
    BarCardComponent
  ],
  declarations: [...imExportComponents, InfoCardComponent],
  exports: [...imExportComponents, ...imExportModule, InfoCardComponent, BarCardComponent],
  providers: [PortfolioAnalysisService, PortfolioAnalysisChartService],
})
export class UiModule {}
