<div
  [ngClass]="{
    'bg-base-900/90 border-base-1000 text-white tooltip-container--dark': theme === 'dark',
    'bg-white border-base-200 tooltip-container--light': theme === 'light',
    'before:-bottom-2': position === 'top',
    'before:-top-2': position === 'bottom',
    'before:left-0 before:w-full before:h-2': position === 'top' || position === 'bottom'
  }"
  class="tooltip-container max-w-72 rounded-lg border p-4 relative before:absolute"
>
  <ng-container *ngTemplateOutlet="contentTemplate; context: context"></ng-container>
</div>
